.destination-page {
  background: url(https://d3c46yygxatk0.cloudfront.net/cdn/images/banner/hero-bg.jpg)
    no-repeat left top;
  background-size: cover;
  margin-bottom: 0px;
  padding-top: 115px;
  padding-bottom: 50px;
  height: 250px;
  position: relative;
  border-radius: 0px 0px 0px 0px;
}

.destination-heading {
  margin-top: 20px;
}

.destination-heading li {
  text-decoration: none;
  list-style: none;
}

.destination-heading a:hover {
  text-decoration: none;
}

.destination-heading li p {
  color: #ffffff;
  font-size: 18px;
}

.des-carousel {
  margin-top: 50px;
}

.des-carousel h2{
  margin-bottom: 20px;
  color: #000000;
  font-weight: 700;
  text-transform: capitalize;
  font-size: 30px;
}

.des-carousel .carousel-con {
  background: #f5f5f5;
  width: max-content;
  border-radius: 16px;
  color: #000000;
  font-weight: 700;
  border: 1px solid #f5f5f5;
}

.des-carousel .carousel-con a {
  color: #000000;
  text-decoration: none;
  display: block;
  padding: 6px 25px;
}

.des-carousel .carousel-con a p {
  margin-bottom: 0;
}

.des-carousel .carousel-con:hover,
.des-carousel .carousel-con:active {
  border: 1px solid #cccccc;
}

.des-carousel .react-multiple-carousel__arrow {
  min-width: 30px;
  min-height: 30px;
  background: #ffffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
}

.des-carousel .react-multiple-carousel__arrow::before {
  color: #000000;
  font-size: 18px;
}

.des-carousel .react-multiple-carousel__arrow:hover,
.des-carousel .react-multiple-carousel__arrow:active {
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
}


.des-container{}

.des-container .des-con-component{
  margin-top: 50px;
  display: flex;
  justify-content: start;
}