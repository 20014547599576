*,
*::before,
*::after {
  box-sizing: border-box;
}

.content {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
}

#airplane-loader {
  margin: 48px;
}
#airplane-take-off {
  transform-origin: bottom center;
  animation-name: take-off;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes take-off {
  0% {
    opacity: 0;
    transform: translate(-40px, 0) rotate(0deg);
  }
  10% {
    opacity: 1;
  }
  25% {
    transform: translate(0, 0) rotate(0deg);
  }
  30% {
    transform: translate(8px, -1px) rotate(-2deg);
  }
  35% {
    transform: translate(16px, -3px) rotate(-4deg);
  }
  40% {
    opacity: 1;
    transform: translate(24px, -5px) rotate(-7deg);
  }
  45% {
    transform: translate(32px, -8px) rotate(-10deg);
  }
  50% {
    opacity: 0;
    transform: translate(40px, -12px) rotate(-16deg);
  }
  100% {
    opacity: 0;
    transform: translate(40px, -12px) rotate(-16deg);
  }
}

#airplane-landing {
  transform-origin: bottom center;
  animation-name: landing;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes landing {
  0% {
    opacity: 0;
    transform: translate(-40px, -12px) rotate(16deg);
  }
  50% {
    opacity: 0;
    transform: translate(-40px, -12px) rotate(16deg);
  }
  55% {
    transform: translate(-32px, -8px) rotate(10deg);
  }
  60% {
    opacity: 1;
    transform: translate(-24px, -5px) rotate(7deg);
  }
  65% {
    transform: translate(-16px, -3px) rotate(4deg);
  }
  70% {
    transform: translate(-8px, -1px) rotate(2deg);
  }
  75% {
    transform: translate(0, 0) rotate(0deg);
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(40px, 0) rotate(0deg);
  }
}
