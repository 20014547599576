.feedback-con{
    background: #ed323757;
    border-radius: 7px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 12px 10px;
}

.feedback-con p{
    font-size: 18px;
    margin: 0;
}

.feedback-con svg{
    font-size: 22px;
    color: #ed3237;
    cursor: pointer;
}

.feedback-con .like{}

.feedback-con .like-con{}

.feedback-con .like-con p{}

.feedback-con .dislike{}

.feedback-con .dislike-con{
    display: flex;
    flex-direction: column;
}

.feedback-con .dislike-con p{}

.feedback-con .dislike-con textarea{
    margin: 10px 0;
    border: none;
    border-radius: 3px;
    height: 80px;
}

.feedback-con .dislike-con button{
    max-width: 100px;
    border: none;
    padding: 7px 10px;
    background: #ed3237;
    color: #ffffff;
    border-radius: 5px;
}