.body-content {
    background: #ededed;
    border: 3px solid #1b416c;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.seats-left-section img:hover {
    transform: scale(1.1);
}
img.add-on-image {
    width: 55px;
}

h2.heading-add {
    font-size: 14px;
    color: #000;
    margin-top: 10px;
    white-space: nowrap;
}

.sec-add {
    display: flex;
    justify-content: flex-start;
    text-align: center;
    margin: auto;
    align-items: center;
}

img.add-section {
    width: 25px;
    height: 25px;
}

.add-sub-header {
    background: #f6f6f6 !important;
    color: #000;
    padding: 20px 10px;
}

p.addons-heading {
    font-size: 14px;
    color: #000;
    text-transform: uppercase;
    font-weight: 400;
}

button.accordion-button.addons {
    background-color: #fff;
}

.second-div-heading {
    border-bottom: 1px solid gray;
}

.hide-add-cards.cross_icon {
    padding: 0px 20px;
}

img.hide_icon {
    width: 25px;
}

.price-addon {
    position: absolute;
    bottom: 0;
    display: block;
    width: -webkit-fill-available;
    padding-right: 10px;
    background-color: #000;
    opacity: 0.8;
    color: #fff;
}

hr.addons.active {
    background: #ffcd2e;
    height: 4px;
    position: absolute;
    bottom: 0;
    z-index: 1;
    opacity: 1;
    width: 100%;
    top: 34px;
    border: none;
}

.hr-link {
    display: inline-block;
    position: relative;

}

hr.addon.active {
    background: #ffcd2e;
    height: 4px;
    position: absolute;
    bottom: 0;
    z-index: 1;
    opacity: 1;
    width: 100%;
    top: 41px;
    border: none;
}

div#baggage,
div#meals,
div#seats {
    position: relative;
    cursor: pointer;
}

.addons-btn {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    top: 50%;
    z-index: 2;
}

.main-carouse-addons {
    position: relative;
    background: #f0f0f0;
    border-top: 1px solid #d0d0d0;
    border-bottom: 1px solid #d0d0d0;
}

.offcanvas-footer {
    background: #f0f0f0;
}

.footer-text {
    font-size: 17px;
    font-weight: 600;
    padding-right: 8px;
    border-right: 1px solid #bfbdbd;
    color: #000;
}

.footer-amount {
    font-size: 26px;
    font-weight: 600;
    color: #062156;
}

.footer-right-net {
    font-size: 12px;
    font-weight: 400;
    color: #908f8f;
}

.offcanvas.offcanvas-end.addons-offcanvas {
    width: 60%;
}

/* .addons-header {
    width: 50%;
} */


button.owl-prev.add-prev,
button.owl-next.add-next {
    background-color: unset;
    border: none;
}

img.images-fluid {
    width: 30px;
}

.seats-section {
    overflow-y: scroll;
    padding: 10px 60px;
    margin: 0px 40px;
}

.seats-left-section {
    transform: rotate(-90deg);
}

.header-card,
.footer-card {
    position: absolute;
    top: 50%;
    transform: rotate(-90deg);
}

.footer-card {
    right: 0;
}

.seats-left-section {
    position: relative;
  }
  p.paraText {
    height: 50px;
}
h5.add-card.charg {
    padding-top: 10px;
}
  .seats-left-section.fleetBlocked .tooltip {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 4px;
    display: none;
    transform: rotate(90deg);
  }
  
  .seats-left-section.fleetBlocked:hover .tooltip {
    display: block;
  }

  
@media screen and (max-width:992px) {
    .offcanvas.offcanvas-end.addons-offcanvas {
        width: 100%;
    }

    .addons-header {
        width: 100%;
    }
    .addons-header-gapping {
        row-gap: 30px;
    }

    .footer-final-addons {
        flex-direction: column;
        row-gap: 10px;
    }

    .slod-row {
        flex-flow: wrap;
        row-gap: 10px;
        padding: 10px 15px;
    }

}