.activity-checkout{
    max-width: 1100px;
    margin: 0 auto;
}

.check-form{
    display: flex;
  align-items: flex-start;
  border: 1px solid #ed3237;
  border-radius: 10px;
  gap: 20px;
  height: 100%;
  padding: 20px 10px;
  margin-bottom: 20px;
}

.check-form .radio-btn{}

.check-form .radio-btn span{}

.check-form .content-block{
    margin-top: 7px;
}

.check-form .content-block h4{}

.check-form .content-block p{
    display: flex;
    flex-direction: column;
}

.check-form .content-block p button{
    display: block;
    max-width: 120px;
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    font-weight: bold;
    margin-top: 7px;
}

.check-form .content-block .time-block{
    display: flex;
    gap: 20px;
    margin-bottom: 15px;
}

.check-form .content-block .time-block .block{
    margin-bottom: 0;
    border: 1px solid #ed3237;
    border-radius: 10px;
    padding: 0 5px;
}

.check-form .content-block .time-block .block button{
    color: #ed3237;
    text-transform: capitalize;
}

.check-form .content-block .time-block .block.active{
    background: #ed3237;
}

.check-form .content-block .time-block .block.active button{
    color: #ffffff;
}

.check-form .content-block .time-block .block.disable{
    border: 1px solid rgba(0, 0, 0, 0.26);
}

.check-form .content-block .time-block .block.disable button{
    color: rgba(0, 0, 0, 0.26);
}

.check-form .content-block .warning-block{
            display: flex;
    gap: 10px;
    background: #ed323757;
    align-items: center;
    padding: 10px;
    border-radius: 6px;
}

.check-form .content-block .warning-block svg{
    font-size: 20px;
}

.check-form .content-block .warning-block p.link{
    font-weight: bold;
    margin-bottom: 0;
}

.check-form .content-block .warning-block p.normal{
    margin-bottom: 0;
}

.check-form .btn-block{
       margin-top: 7px;
    padding: 0 20px;
    width: 900px;
    display: grid;
    align-self: stretch;
}

.check-form .btn-block .total-cost{
    margin-bottom: 12px;
}

.check-form .btn-block .total-cost p.prize{
    font-weight: bold;
        margin-bottom: 0;
        text-align: right;
}

.check-form .btn-block .total-cost p.info{
    font-size: 13px;
    margin-bottom: 0;
    text-align: right;
}

.check-form .btn-block .book-now{
    margin-top: auto;
}

.check-form .btn-block .book-now p{
    background: #ed3237;
    padding: 10px;
    color: #ffffff;
    text-align: center;
    max-width: 200px;
    margin: 0 0 0 auto;
    border-radius: 6px;
}