.activity-overview {
  max-width: 1100px;
  margin: 0 auto;
}

.activity-overview .activity-content {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 20px;
  margin-top: 20px;
}

.activity-overview .activity-content .overview-header {
  margin-bottom: 20px;
}

.activity-overview .activity-content .overview-header h3 {
  font-size: 25px;
}

.activity-overview .activity-content .overview-header h3 svg {
  float: right;
}

.activity-overview .activity-content .overview-desc {
}

.activity-overview .activity-content .overview-desc p {
}

.activity-overview .activity-content .overview-accordion {
  overflow: hidden;
  transition: height 0.5s ease;
}

.activity-overview .activity-content .overview-accordion.open {
  height: max-content !important;
}

.activity-overview .overview-accordian .overview-list {
}

.activity-overview .overview-list ul {
  display: flex;
  list-style: none;
}

.activity-overview .overview-list li.yes {
  width: 100%;
}

.activity-overview .overview-list li.no {
  width: 100%;
}

.activity-overview .overview-list li .list-content {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.activity-overview .overview-list .list-content svg {
  font-size: 25px;
  margin-right: 10px;
}

.activity-overview .overview-list .list-content p {
  margin-bottom: 0;
}

.overview-accordion .overview-button{
  margin-top: 10px;
}

.overview-accordion .overview-button button{
  border: 1px solid #ed3237;
  border-radius: 7px;
  background: #ffffff;
  color: #000000;
  padding: 10px 30px;
}