@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800&display=swap");

html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  color: #878787;
  font-family: "Open Sans", sans-serif;
  font-weight: 100;
  padding: 0px;
  margin: 0px;
}

img.left_image {
  height: auto;
}

td[data-label="Check in Baggage"],
td[data-label="Cabin Baggage"] {
  word-break: break-all;
}
.inline-form2.hotel-tab .inline-text,
.inline-form2.hotel-tab .inline-text1 {
  width: 16.16%;
}

.navbar-nav .nav-link {
  color: #2f4858;
  font-weight: 500;
  margin-right: 61px;
}

.navbar-nav .nav-link:hover {
  text-decoration: none;
  color: #ed3237;
}
a.back-to-seach.btn {
  background: #ed3237;
  color: #fff;
  border-radius: 4px;
  max-width: 100%;
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.main-btn-div {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
  height: 50vh;
}
p.fare-rule-text {
  line-height: 1.5;
  white-space: pre-line;
  text-align: justify;
  max-height: 250px;
  margin: 20px 0px;
  overflow-y: scroll;
}
main.container.hidden-main {
  display: none;
}
.tnb {
  justify-content: right;
}
.tabbing-bar.parent-active {
  z-index: 1000;
}
.tnb .navbar-nav {
  margin: auto;
}

.hotel-carousel.owl-theme .owl-nav {
  margin-top: -40px;
  margin-right: 0px;
  padding: 0px 30px;
}

.tnb .navbar-nav .nav-item:last-child .nav-link {
  margin-right: 0px;
}

.hero-section {
  background: url("https://d3c46yygxatk0.cloudfront.net/cdn/images/banner/hero-bg.jpg")
    no-repeat left calc(-115px);
  background-size: cover;
  margin-bottom: 0px;
  padding-top: 115px;
  /* padding-bottom: 50px; */
  padding-bottom: 160px;
  height: auto;
  position: relative;
  background-position: center center;
  border-radius: 0px 0px 0px 0px;
}
.hero-section::after {
  content: "";
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  top: 0px;
  position: absolute;
  border-radius: 0px 0px 0px 0px;
}

.main_content.hops_position {
  position: relative;
}
.change-plan.hops-changes-plan {
  position: absolute;
  top: 12%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, 50%);
}

h5.hops.text.plan {
  font-size: 15px;
  font-weight: 700;
}

.hero-section h1 {
  color: #fff;
  font-size: 36px;
  line-height: normal;
  max-width: 100%;
  font-weight: 700;
}

.hero-section-top {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  align-items: center;
  z-index: 1;
  position: relative;
  margin-top: 80px;
  margin-bottom: 50px;
}

.hero-section-left {
  text-align: center;
  width: 100%;
}

.card-tempa-bay {
  background: #fff;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 7px 29px rgba(100, 100, 111, 0.2);
}

.tabbing-bar .nav-link {
  min-width: 120px;
  text-align: center;
  background: #fff;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  box-shadow: 0px 0px 10px rgba(100, 100, 111, 0.2);
  border: none;
  color: #6d7f8a;
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: 500;
}

.tabbing-bar {
  max-width: 100%;
  margin: auto;
  padding-right: 10px;
  position: relative;
  z-index: 2;
}

.tabbing-bar .tab-content {
  background: #fff;
  padding: 20px;
  border-radius: 0px 6px 6px 6px;
  box-shadow: 0px 0px 10px rgba(100, 100, 111, 0.2);
  margin-top: 0px;
  z-index: 1;
  position: relative;
}

.tabbing-bar .nav-link {
  border-radius: 0px;
}

.tabbing-bar .nav-link.active {
  background: #ed3237;
  color: #fff;
}

.tabbing-bar .nav-link:first-child {
  border-top-left-radius: 6px;
}

.tabbing-bar .nav-link:last-child {
  border-top-right-radius: 6px;
}

.tabbing-bar .nav-tabs {
  border: none;
}

.select_dropdown .select-option {
  font-size: 16px;
  font-weight: 100;
  color: #000;
  text-wrap: wrap;
  line-height: 0;
}

.select-dropdown .dropdown-menu {
  padding: 0.5rem;
  font-size: 14px;
  min-width: 11rem;
}

.select-dropdown .dropdown-toggle {
  color: #101010;
}

.select-dropdown a.dropdown-toggle:hover {
  color: #101010;
  text-decoration: none;
}

.select-dropdown .form-check .form-check-input {
  float: right;
  border: none;
}

.select-dropdown .form-check-input:checked[type="radio"] {
  background: url("../images/check.png") no-repeat left top;
}

.select-dropdown .form-check-input:active {
  filter: none;
}

.dropdown-menu {
  color: #707070;
}

.select-dropdown .form-check {
  padding-left: 0.5em;
}

.select-dropdown .form-check-input:checked + label {
  color: #2f4858;
}

.select-dropdown {
  margin-right: 30px;
}

.select-dropdown label {
  cursor: pointer;
}

.inline-text .dropdown.select-dropdown .dropdown-toggle::after {
  content: "";
  background: url("../images/CaretDown.png") no-repeat left top;
  border: none;
  width: 16px;
  height: 16px;
  position: relative;
  top: 6px;
  display: none;
}

.tnb .btn-login {
  position: relative;
  width: auto;
}

.tnb .btn-login::after {
  content: "";
  background: url("../images/CaretDown-white.svg") no-repeat left top;
  border: none;
  width: 16px;
  height: 16px;
  position: relative;
  top: 6px;
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 10px;
  display: none;
}

.inline-form {
  display: flex;
  flex-wrap: wrap;
}

.top-row {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #2f4858;
}

.passengers-menu li {
  font-size: 12px;
}

.inline-form2 {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  align-items: center;
}

.inline-form2 .inline-text {
  margin-right: 20px;
  position: relative;
  width: 100%;
}

.inline-form2 .inline-text img {
  position: absolute;
  left: 10px;
  top: 43px;
}

.form-control {
  border-radius: 8px;
  border: 1px solid #d9d9d9;
}

.inline-form2 .inline-text:last-child {
  margin-right: 0px;
}

.inline-form2 .form-control {
  padding-left: 35px;
}

.destination {
  width: 390px;
  max-width: 100%;
}

.card3 {
  text-align: center;
}

.card3 p {
  font-size: 14px;
  max-width: 270px;
  margin: auto;
}

.icon-box {
  width: 80px;
  height: 80px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ed3237;
  border-radius: 50%;
  margin-bottom: 20px;
}

h1 {
  color: #2f4858;
  font-size: 48px;
  line-height: 65.37px;
}

h2 {
  color: #2f4858;
  font-size: 36px;
  font-weight: 700;
}

h3 {
  color: #2f4858;
  font-size: 20px;
  font-weight: 700;
}

.p1 {
  max-width: 608px;
  margin: auto;
  text-align: center;
  margin-bottom: 50px;
}

.hero-section-right {
  position: relative;
  top: 80px;
}

.modal.fade.photos_popup.show {
  opacity: 1;
}

.talk-to-agents {
  color: #fff;
  position: relative;
  background: url("https://d3c46yygxatk0.cloudfront.net/cdn/images/banner/talk-to-agent.jpg")
    no-repeat left top;
  background-size: cover;
  margin-top: 120px;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

select.form-select.form_select {
  font-weight: 600;
  font-size: 16px;
}

.talk-to-agents h3 {
  color: #fff;
  font-size: 36px;
}

.talk-to-agents p {
  max-width: 345px;
  margin-left: auto;
  margin-right: auto;
}

.talk-to-agents .container {
  position: relative;
  z-index: 1;
}

.talk-to-agents .btn {
  height: 40px;
}

.talk-to-agents::after {
  content: "";
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

a,
a:focus,
a:hover,
.btn {
  outline: none;
  text-decoration: none;
  transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
}

a {
  color: #3987c8;
}

a:hover {
  text-decoration: underline;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #3987c8;
}

.linkText {
  color: #3987c8;
  position: relative;
  z-index: 1;
}

.btn-login,
.lg-btn {
  background: #ed3237;
  color: #fff;
  border-radius: 4px;
  max-width: 100%;
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.btn-login:hover,
.btn-login:focus,
.lg-btn:hover,
.lg-focus:hover {
  background: #ed3237;
  color: #fff;
  box-shadow: 0 0 0 0.25rem rgba(237, 50, 55, 0.25);
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus {
  background: #2a6eb4;
  border: 1px solid #2a6eb4;
}

.outline-btn {
  border: 1px solid #ed3237;
  background: #fff;
  color: #ed3237;
  width: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.outline-btn:hover,
.outline-btn:focus {
  background: #ed3237;
}

span.info-icon {
  padding: 0 8px;
  margin: 10px;
  border: 1px solid #e53d30;
  border-radius: 50%;
  font-weight: 700;
  color: #e53d30;
  font-size: 14px;
}

/* tooltip css */
.tooltip {
  position: relative;
  display: inline-block;
  opacity: 1;
  cursor: pointer;
  font-size: 16px;
  cursor: pointer;
  margin: 0px 0px 8px 0px;
  font-family: "Open Sans", sans-serif;
  font-weight: 100;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 400px;
  background-color: #ed3237;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 25%;
  margin-left: 0px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #ed3237 transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.small-text {
  font-size: 12px;
}

.lg-btn {
  width: 100%;
}

.btn-check:focus + .btn,
.btn:focus,
.btn-login:hover,
.lg-btn:hover,
.lg-btn:focus {
  box-shadow: 0 0 0 0.25rem rgba(237, 50, 55, 0.25);
}

footer {
  margin-top: 60px;
}

footer h4 {
  color: #2f4858;
  font-size: 18px;
  font-weight: 600;
}

footer ul {
  list-style: none;
}

footer li a {
  color: #878787;
  display: table;
  margin-bottom: 5px;
}

footer li a:hover {
  color: #ed3237;
  text-decoration: none;
}

.copyright {
  border-top: 1px solid rgba(16, 16, 16, 0.1);
  padding-top: 20px;
  margin-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.offer-card {
  box-shadow: 0px 0px 10px rgba(100, 100, 111, 0.2);
  border-radius: 6px;
  padding: 20px 10px;
  margin-bottom: 15px;
  margin-top: 10px;
  margin-right: 0px;
}

.offer-col {
  display: flex;
  flex-direction: column;
}

.col-left {
  min-width: 100%;
  margin-right: 0px;
  margin-bottom: 15px;
}

.col-left img {
  border-radius: 6px;
  max-width: 100%;
  width: 100%;
  min-height: 190px;
}

h3.place-heading {
  color: #2f4858;
  font-size: 20px;
  font-weight: 700;
}

.offer-col .col-right h4 {
  color: #2f4858;
  font-size: 16px;
  font-weight: 700;
}

.res_section {
  display: none;
}

.dot-arrow {
  border: 1px solid #878787;
  width: 100%;
  margin: 0px 5px;
  border-style: dashed;
  align-items: center;
  display: flex;
  justify-content: space-around;
}

span.dot-span {
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #d32f2f;
  box-shadow: 0 0 5px 5px #fff;
}

.offer-col .col-right p {
  font-size: 14px;
  padding-right: 30px;
}

.link-gray {
  color: #2f4858;
}

.link-gray:hover {
  color: #ed3237;
}

.link-sm {
  color: #2f4858;
  font-size: 12px;
}

.carousel-owl {
  position: relative;
}

.carousel-owl .owl-nav {
  position: absolute;
  right: 0px;
  top: 0px;
}

.carousel-owl .owl-theme .owl-nav {
  margin-top: -40px;
  margin-right: 5px;
}

.carousel-owl .owl-theme .owl-nav [class*="owl-"] {
  color: #ed3237;
  font-size: 24px;
  padding: 0 !important;
  transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
}

.carousel-owl .owl-theme .owl-nav [class*="owl-"]:hover {
  color: #fff;
  background: #ed3237;
}

.carousel-owl .owl-theme .owl-nav [class*="owl-"] span {
  position: relative;
  top: -7px;
}

.owl-carousel .owl-nav button {
  border-radius: 50% !important;
  border: 1px solid #ed3237 !important;
  display: inline-block;
  height: 30px;
  width: 30px;
}

.offers-bottom {
  text-align: center;
  margin-top: 25px;
}

.site-modal {
  background: #e4f0ff;
}

.modal-head {
  font-size: 36px;
  margin-bottom: 20px;
}

.modal-body .btn-close {
  position: absolute;
  top: -30px;
  right: 0px;
  opacity: 1;
  border-radius: 6px;
}

.site-modal .modal-body {
  padding: 0px;
}

.site-modal .modal-left {
  padding: 0px;
  text-align: center;
  max-width: 304px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.site-modal img {
  max-width: 100%;
}

.text-left1 {
  text-align: left;
}

.site-modal .modal-body label {
  color: rgba(16, 16, 16, 0.8);
  font-size: 14px;
  margin-bottom: 5px;
}

.signin-text {
  position: relative;
  color: rgba(16, 16, 16, 0.8);
}

.signin-text::after {
  content: "";
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 1px;
  top: 13px;
  left: 0px;
  position: absolute;
}

ul.social-media.payment-method img:last-child {
  width: 100%;
  height: 100%;
}

.res_show {
  display: none;
}

.signin-text span {
  display: inline-block;
  background: #e4f0ff;
  position: relative;
  z-index: 1;
  padding-left: 5px;
  padding-right: 5px;
}

.fb-btn {
  background: #1877f2;
  font-weight: 500;
}

.fb-btn:hover {
  background: #1877f2;
  box-shadow: 0 0 0 0.25rem rgba(24, 119, 242, 0.25);
}

.fb-btn:focus {
  background: #1877f2;
  box-shadow: 0 0 0 0.25rem rgba(24, 119, 242, 0.25);
}

.fb-btn img {
  margin-right: 5px;
}

.google-btn {
  background: #fff;
  color: #2f4858;
  border: 1px solid #2f4858;
  font-weight: 500;
}

.google-btn img {
  margin-right: 5px;
}

.google-btn:hover,
.google-btn:focus {
  box-shadow: 0 0 0 0.25rem rgba(47, 72, 88, 0.25);
  background: #fff;
  color: #2f4858;
}

.modal-content {
  border: none;
}

.modal-right img {
  border-radius: 0px 4px 4px 0px;
}

.site-modal .form-control {
  font-size: 14px;
}

.form-label2 {
  font-size: 16px;
}

.otp {
  position: relative;
  display: block;
}

.otp a {
  position: absolute;
  right: 10px;
  top: 8px;
  z-index: 1;
  display: inline-block;
  font-size: 12px;
  color: #ed3237;
}

.modal-head2 {
  display: flex;
  text-align: left;
  font-size: 18px;
  align-items: center;
  margin-bottom: 30px;
}

.modal-head2 img {
  margin-right: 10px;
}

.form-select {
  height: 35px;
  font-size: 14px;
  border-radius: 8px;
}

.date {
  position: relative;
  display: block;
}

.date img {
  position: absolute;
  right: 10px;
  top: 9px;
}

.link-block {
  color: rgba(16, 16, 16, 0.8);
  font-size: 14px;
  display: block;
}

.link-block:hover {
  color: #ed3237;
  text-decoration: none;
}

.dropdown-menu2 {
  font-size: 14px;
}

.dropdown-menu2 .dropdown-item {
  vertical-align: middle;
  padding: 0.5rem 10px;
}

.dropdown-menu2 .dropdown-item:hover,
.dropdown-menu2 .dropdown-item:active,
.dropdown-menu2 .dropdown-item:focus {
  text-decoration: none;
  color: rgba(16, 16, 16, 1);
  background: #e4f0ff;
}

.dropdown-menu2 .dropdown-item img {
  vertical-align: bottom;
}

.dropdown-menu2 .dropdown-divider {
  margin: 0px 10px;
}

.social-media {
  display: flex;
}

.social-media a img {
  display: inline-block;
  transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
}

.social-media a {
  display: inline-block;
  margin-right: 20px;
}

.social-media a:hover img {
  transform: scale(1.2);
}

.social-media li:last-child a {
  margin-right: 0px;
}

.footer-row {
  justify-content: space-between;
}

.footer-row .col-sm-4 {
  width: auto;
}

.outline-link {
  margin-right: 30px;
  background: #fff;
  color: #2f4858;
  border: 1px solid #2f4858;
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 4px;
  width: 120px;
}

.outline-link:hover,
.outline-link:focus,
.outline-link:active,
.outline-link:focus-visible,
.outline-link:focus-within {
  text-decoration: none;
  background: #2f4858;
  color: #fff;
  border: 1px solid #2f4858;
  box-shadow: 0 0 0 0.25rem rgba(47, 72, 48, 0.25);
}

.hide-div {
  display: none;
}

.outline-link-lg {
  width: auto;
  margin-right: 0px;
}

.add-city .form-control {
  padding-left: 35px;
  width: 295px;
  max-width: 100%;
}

.add-city {
  display: inline-block;
}

.add-city .inline-text img {
  position: absolute;
  left: 10px;
  top: 22px;
}

.add-city-div {
  margin-top: 20px;
}

.add-city .inline-text {
  display: inline-block;
  position: relative;
  margin-right: 18px;
}

.hide-div.inline-block {
  display: inline-block !important;
}

.tnb .dropdown-menu2 {
  right: 0px;
  left: auto;
}

.tnb-fixed {
  position: absolute;
  z-index: 10;
  width: 100%;
  top: 0;
}

.tnb-fixed .outline-link {
  color: #ed3237;
  border: 1px solid #ed3237;
}

.tnb-fixed .outline-link:hover {
  color: #fff;
  border: 1px solid #ed3237;
  background: #ed3237;
}

.tnb-fixed .outline-link:hover,
.tnb-fixed .outline-link:focus,
.tnb-fixed .outline-link:active,
.tnb-fixed .outline-link:focus-visible,
.tnb-fixed .outline-link:focus-within {
  text-decoration: none;
  background: #ed3237;
  color: #fff;
  border: 1px solid #ed3237;
  box-shadow: 0 0 0 0.25rem rgba(237, 50, 55, 0.25);
}

.offer-card-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.offer-card-footer a.link-gray {
  color: #ed3237;
}

.owl-carousel-mobile {
  display: none;
}

.payment-method li {
  display: inline-block;
  margin-right: 5px;
  border: 1px solid rgba(16, 16, 16, 0.1);
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
}

.payment-method {
  flex-wrap: wrap;
  gap: 11px;
}

.payment-method li img.visa {
  max-height: 12px;
}

.payment-method li.no-border {
  border: none;
  padding-left: 0px;
  padding-right: 0px;
}

main {
  padding-top: 80px;
}

.profile-card {
  box-shadow: 0px 0px 10px rgba(100, 100, 111, 0.2);
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 15px;
  margin-top: 10px;
  margin-right: 10px;
  height: 100%;
  padding-bottom: 0px;
}

.profile-card p {
  font-size: 14px;
}

.profile-head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.box-img {
  display: inline-flex;
  width: 100px;
  height: 100px;
  background: #2f4858;
  border-radius: 6px;
  font-size: 48px;
  font-weight: 700;
  color: #fff;
  justify-content: center;
  align-items: center;
  line-height: normal;
  max-width: 100%;
}

.label1 {
  font-size: 14px;
  color: #101010;
  margin-bottom: 10px;
}

.label2 {
  font-size: 14px;
  margin-bottom: 10px;
}

.pl15 {
  padding-left: 8px;
}

.personal-modal .modal-left {
  max-width: 100%;
  text-align: left;
  padding: 40px;
}

.personal-modal {
  max-width: 700px;
}

.link-red {
  display: block;
  font-size: 14px;
  color: #ed3237;
}

.inline-text3 {
  display: inline-block;
  position: relative;
  width: 100%;
}

.inline-text3 img {
  position: absolute;
  left: 10px;
  top: 10px;
}

.inline-text3 .form-control {
  padding-left: 35px;
  width: 100%;
}

b {
  font-weight: 700;
}

.file-box {
  position: relative;
  max-width: 100px;
}

.file-box input {
  position: absolute;
  z-index: 1;
  opacity: 0;
}

.file-box .link-red {
  font-size: 12px;
  text-align: center;
}

.modal-login-details {
  max-width: 400px;
}

.mobile-select {
  display: flex;
}

.mobile-select select {
  max-width: 80px;
  border-radius: 8px 0px 0px 8px;
  border-right: none;
}

.mobile-select .form-control {
  border-radius: 0px 8px 8px 0px;
}

.country-select select {
  max-width: 100%;
  display: flex;
}

.profile-card label {
  font-weight: 600;
  display: block;
}

.file-box {
  max-width: 20px;
  text-align: center;
}

.link-box {
  display: flex;
  max-width: 100px;
  justify-content: space-around;
  margin-top: 5px;
}

.mobile-select {
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  background: #fff;
}

.mobile-select .form-control {
  border: none;
}

.country-code {
  display: flex;
  align-items: center;
  width: 55px;
  height: 100%;
  padding-left: 10px;
  font-size: 14px;
  color: #212529;
  padding-left: 10px;
  margin-top: 6px;
  position: relative;
}

.country-code img.caret {
  position: absolute;
  right: 0px;
}

.flag {
  margin-right: 10px;
}

.caret {
  margin-left: 5px;
}

.mobile-data-list .dropdown-toggle::after {
  display: none;
}

.country-code-box .form-control {
  border-radius: 8px;
  border: 1px solid #d9d9d9;
}

.mobile-data-list .dropdown-menu {
  width: auto;
  overflow-y: auto;
  font-size: 12px;
  max-height: 300px;
}

.country-code-box {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 10px;
  margin-top: 10px;
  position: relative;
}

.country-code-box .search-icon {
  position: absolute;
  z-index: 1;
  left: 10px;
  top: 10px;
}

.country-code-box .form-control {
  padding-left: 35px;
}

.mobile-data-list .dropdown .dropdown-item {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.mobile-select a:hover {
  text-decoration: none;
}

.flag {
  width: 20px;
}

.verified {
  color: #4a8e00;
  font-weight: 400;
  font-size: 12px;
  display: inline-block;
  margin-left: 10px;
}

.verified img {
  position: relative;
  top: -1.5px;
}

.pending {
  font-weight: 400;
  font-size: 12px;
  display: inline-block;
  margin-left: 10px;
  filter: grayscale(10);
}

.inline-form .form-check {
  margin-right: 30px;
  margin-bottom: 10px;
  color: #101010;
}

.inline-form .form-check:last-child {
  margin-right: 0px;
}

.h1-inner {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
}

.tabbing-bar {
  margin-bottom: 60px;
}

.left-side {
  background: #f5faff;
  border: 1px solid #cfe4f9;
  padding: 20px;
  border-radius: 6px;
  font-size: 14px;
}

.block {
  margin-bottom: 30px;
}

.h3-bold {
  font-weight: 600;
}

.slidecontainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.slidecontainer input {
  width: 100%;
  margin-bottom: 10px;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  background: red;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: red;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: red;
  cursor: pointer;
}

/*----Responsive starts here -----*/

@media only screen and (max-width: 1280px) {
  .add-city .form-control {
    width: 250px;
  }
}

@media only screen and (max-width: 1199px) {
  .navbar-nav .nav-link {
    margin-right: 0px;
  }

  .offer-card {
    margin-left: 5px;
  }

  .offer-col .col-right p {
    padding-right: 0px;
  }

  .hero-section h1 {
    font-size: 42px;
    line-height: normal;
  }

  .add-city .form-control {
    width: 205px;
  }
}

@media only screen and (max-width: 991px) {
  .navbar-brand img {
    max-height: 40px;
  }

  .navbar-nav .nav-link {
    font-size: 14px;
  }

  .navbar-toggler {
    border: 1px solid #ed3237;
  }

  .navbar-toggler-icon {
    background: url("../images/List.svg") no-repeat left top;
    border: none;
    width: 32px;
    height: 32px;
    background-size: 100%;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .hero-section-left {
    width: 100%;
  }

  .hero-section-right {
    width: 100%;
    top: 0;
  }

  .hero-section-right img {
    width: 100%;
  }

  .hero-section h1 {
    font-size: 36px;
    line-height: normal;
  }

  .hero-section-top {
    align-items: center;
  }

  .tnb {
    background: #e4f0ff;
  }

  .navbar .container {
    position: relative;
  }

  .tnb {
    position: absolute;
    width: 100%;
    top: 50px;
    z-index: 1;
    left: 0;
    padding: 20px;
  }

  .modal-row1 {
    flex-direction: column;
  }

  .modal-row1 .col-sm-6 {
    width: 100%;
  }

  .site-modal .modal-left {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .hero-section h1 {
    font-size: 34px;
  }

  .city-btn {
    margin-top: 0px;
  }

  .multi-city-form {
    flex-wrap: wrap;
  }

  .inline-form2 {
    flex-wrap: wrap;
  }

  .inline-form2 .inline-text {
    margin-right: 0px;
    margin-bottom: 10px;
  }

  .add-city .inline-text {
    margin-right: 0px;
    width: 100%;
    margin-bottom: 10px;
  }

  .add-city .form-control {
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .hero-section-top {
    flex-direction: column-reverse;
  }

  .carousel-owl .owl-hotel-room .owl-nav {
    display: flex !important;
    padding: 0px 24px 0px 0px;
  }
  .hero-section-left {
    width: 100%;
    margin-top: 20px;
    text-align: center;
  }

  .hero-section-right {
    width: 100%;
    top: 0;
  }

  .tnb .navbar-nav {
    text-align: center;
  }

  .hero-section {
    margin-bottom: 60px;
    background-size: cover;
  }

  .tabbing-bar {
    margin-top: 30px;
    padding-right: 0px;
  }

  .inline-form2 {
    flex-direction: column;
  }

  .inline-form2 .inline-text {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .p1 {
    margin-bottom: 30px;
  }

  .card3 {
    margin-bottom: 30px;
  }

  .talk-to-agents {
    margin-top: 40px;
  }

  footer {
    margin-top: 30px;
    text-align: center;
  }

  footer .col-sm-3 {
    margin-bottom: 20px;
  }

  .copyright {
    flex-direction: column;
    margin-top: 0px;
  }

  .copyright img {
    object-fit: contain;
    margin-bottom: 10px;
  }

  .tnb form {
    justify-content: center;
  }

  .navbar-toggler-icon {
    width: 22px;
    height: 22px;
  }

  .navbar-brand img {
    max-height: 40px;
  }

  .carousel-owl .owl-theme .owl-nav {
    margin-top: -50px;
  }

  .footer-row .col-sm-4 {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
  }

  .footer-row .col-sm-4 li {
    text-align: center;
  }

  footer li a {
    font-size: 14px;
    display: inline-block;
  }

  .social-media {
    justify-content: center;
  }

  .add-city .inline-text {
    width: 100%;
    margin-bottom: 10px;
  }

  .add-city .form-control {
    width: 100%;
  }

  .city-btn {
    width: 100%;
    margin-top: 0px;
  }

  .tabbing-bar .nav-link {
    min-width: 100px;
  }

  .select-dropdown {
    margin-right: 10px;
  }

  .carousel-owl .owl-theme .owl-nav {
    display: block;
  }

  .card-desktop {
    display: none;
  }

  .hero-section h1 {
    font-size: 24px;
  }

  .owl-carousel-mobile {
    display: block;
  }

  .modal-body .btn-close {
    top: 10px;
    right: 10px;
  }

  .modal-btn {
    width: 100%;
  }

  .profile-card p {
    margin-bottom: 0px;
  }

  .inline-form .form-check {
    margin-top: 10px;
    margin-bottom: 0px;
  }
}

/* new css */
.block > ul > li {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.block > ul > li .form-check {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.block > ul > li > a {
  justify-content: right;
  display: flex;
  width: 100%;
  color: #ed3237;
}

.main_container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.card.card_active {
  background: #ed3237;
  color: #fff;
  border: 1px solid #ed3237;
}

.main_content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: auto;
  align-items: baseline;
  row-gap: 30px;
}

.main_content .block {
  display: flex;
  justify-content: center;
  margin: auto 0px;
  align-items: baseline;
}

.card-content p {
  font-size: 16px;
}

.card-content.content_body {
  text-align: center;
}

button.btn.btn-select {
  border: 1px solid #ed3237;
  border-radius: 4px;
  font-size: 16px;
  padding: 5px 30px;
}

a.dropdown-toggle_s {
  color: #878787;
}

a.dropdown-toggle_s:hover {
  text-decoration: none;
}

.card.card_section {
  box-shadow: 0px 0px 10px rgba(100, 100, 111, 0.2);
  margin: 25px 0px;
}

.card-footer-section {
  display: flex;
  justify-content: space-between;
  padding: 20px 5px;
}

.card-left {
  color: #008000;
  font-size: 12px;
}

.card-right {
  color: #ed3237;
  font-size: 12px;
}

.card-left:hover {
  color: #008000;
  text-decoration: none;
}

.card-right:hover {
  color: #ed3237;
  text-decoration: none;
}

.change-plan {
  text-align: center;
  padding: 35px 0px;
}

h3 > span {
  color: #010101;
  font-size: 16px;
  font-weight: normal;
}

.card.view_details {
  background: #f5faff;
  border: 1px solid #cfe4f9;
}

.block-view-details {
  display: flex;
  gap: 15px;
  align-items: center;
  padding-bottom: 1.5rem;
  padding-top: 0.5rem;
}

.book-now-btn {
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
}

a.card-right {
  cursor: pointer;
}

.card_cheapest_section,
.card_fastest_section {
  cursor: pointer;
}

.main_content .block .card-img {
  margin-right: 20px;
}

.header_section {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: auto;
  align-items: center;
  column-gap: 20px;
  padding-bottom: 15px;
}

.header_section .h3-bold {
  text-align: center;
  align-items: center;
  display: flex;
}

span.hading_check {
  color: #101010;
  font-weight: 600;
}

.block.block_right {
  margin-bottom: 0px;
  position: relative;
}

hr {
  margin: 0rem 0rem 1.2rem 0rem;
}

.left-side.left-side_1 {
  margin-top: 20px;
  background: #fff;
  border: 1px solid #cfe4f9;
  margin-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.input_group {
  background-color: #ed3237;
}

input.form-control {
  font-size: 12px;
}

span.res_text {
  color: #008000;
  font-size: 12px;
  line-height: normal;
}

li.right_btn.py-1 {
  display: block;
}

span.text_btn {
  font-weight: 400;
  font-size: 0.8rem;
  color: #212529;
}

.login_section h3 {
  font-size: 14px;
}

button.col-sm-3 col-md-3 pb-4.btn_guest {
  width: auto;
  border: 1px solid #ed3237;
  background-color: #fff;
  color: #000;
}

button.col-sm-3 col-md-3 pb-4.btn_login {
  width: auto;
}

label.email_text {
  font-size: 12px;
}

.card.card_checkout {
  box-shadow: 0px 0px 10px rgba(100, 100, 111, 0.2);
  margin-bottom: 20px;
  background-color: #f5faff;
}

.row_container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;
  margin-bottom: 20px;
}

.card_main_content {
  display: flex;
  justify-content: center;
  margin: 0px;
  align-items: baseline;
}

.main_content .card_block {
  display: flex;
  justify-content: center;
  margin: 0px;
  align-items: baseline;
}

.block .content_body {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  column-gap: 20px;
}

span a.second_card {
  float: right;
  color: #ed3237 !important;
  font-size: 12px !important;
}

div#child_row,
div#infant_row {
  display: none;
  padding-top: 20px;
}

div#first_tab {
  padding-top: 20px;
}

p.text-left {
  color: #010101;
}

label.form-check-label {
  color: #000;
  /* font-weight: 700; */
}

h3#child_1,
h3#infant_1,
h3#adult_1 {
  cursor: pointer;
}

p.text-important {
  color: #e57d02 !important;
}

.payment_btn {
  text-align: left;
}

button.btn.btn-login.btn_guest {
  background-color: #fff;
  color: #000;
  border: 1px solid #ed3237;
}

.col-md-6.col-sm-6 .payment-method {
  justify-content: flex-end;
}

.heading_check {
  color: #101010;
  font-weight: 600;
  font-size: 12px;
}

.text-table {
  color: #2f4858;
  font-family: Open Sans;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.status-co {
  color: #008000;
}

.line-ind {
  text-indent: 85px;
  color: #010101;
}

.box-ind {
  width: 50%;
  display: grid;
  justify-content: center;
  margin: auto;
  text-align: end;
}

.box-ind-main {
  text-align: center;
  border: 1px solid rgb(254 245 240);
  background-color: #fff;
  padding: 30px 0px;
  margin: auto;
  display: flex;
  justify-content: center;
  margin-top: 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.main-box:last-child {
  border: none;
}

.main_content.main_card_content {
  justify-content: center;
  column-gap: 10%;
  padding: 10px 0px;
}

ul.confirmation-content li {
  line-height: 2;
  color: #010101;
}

.block_main_container {
  display: flex;
  justify-content: space-between;
}

.sub-heading-confirmation {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: auto;
  align-items: center;
  margin-right: 30px;
  padding-bottom: 15px;
  row-gap: 20px;
}

.card_confirmation {
  background: #f5faff;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: white;
}

.bottom_text {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  bottom: 0px;
  margin-bottom: 25px;
}

.card-content.content_body {
  display: block;
}

.row_container::after {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: gray;
  position: absolute;
  left: 79px;
  margin-top: 8px;
}

.main_div::before {
  content: "";
  border-right: 1px solid;
  position: absolute;
  left: 80px;
  width: 5px;
  height: 55%;
  z-index: 5;
  margin-top: 11px;
}

.payment_container::after {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: gray;
  position: absolute;
  left: 65px;
  margin-top: 8px;
}

.payment_div::before {
  content: "";
  border-right: 1px solid;
  position: absolute;
  left: 65px;
  width: 5px;
  height: 53%;
  z-index: 5;
  margin-top: 11px;
}

.payment_container {
  display: flex;
  flex-wrap: nowrap;
  column-gap: 40px;
  margin-bottom: 20px;
}

.payment_col {
  display: flex;
  align-items: center;
}

li.payment-list {
  margin-bottom: 0px;
}

.login_section .block {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  gap: 10px;
}

.form-contact {
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
}

.select-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

button.btn.outline-link.outline-link-lg.city-btn {
  background: #ed3237;
  color: #fff;
  border-radius: 4px;
  max-width: 100%;
  font-size: 16px;
  padding-top: 5px;
  border: 1px solid #ed3237;
  padding-bottom: 5px;
}

ul.ul-navbar {
  display: flex;
  list-style: none;
}

.inline-form2 .form-control.form_flight,
.add-city .form-control {
  line-height: 4;
}

div#add-another-city {
  width: 100%;
}

.heading_option {
  color: #000;
  font-weight: 700;
}

.box-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 25px;
  cursor: pointer;
}

.box-subcontent {
  display: flex;
  padding: 0px 25px;
  justify-content: space-between;
}

.confirm-box {
  width: 100%;
}

ul.box-dropdown {
  display: none;
}

.image-arrow {
  position: absolute;
  display: flex;
  margin-top: 10px;
  margin-left: 6px;
  transform: rotate(90deg);
}

img.image-arrow.active {
  transform: rotate(180deg);
}

.form-check-input:checked {
  background-color: #ed3237;
  border-color: #ed3237;
}

.depart-heading {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 10px;
}

.depart-body {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;
  padding: 5px 10px;
  margin-top: 10px;
}

.depart-text {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

button.change-flight-btn {
  border: none;
  background-color: transparent;
  color: #ed3237;
}

span.depart-number {
  display: inline-block;
  border-radius: 2px 2px 2px 2px;
  padding: 4px 8px;
  line-height: 14px;
  font-weight: 500;
  background: #ed3237;
  color: #fff;
}

.card_title {
  font-size: 16px;
}

span.card_text {
  font-size: 14px;
}

img.multi-icon {
  position: inherit !important;
}

span.date_formate {
  position: absolute;
  left: 48px;
  top: 40px;
}

button.search_record {
  width: 150px;
}

img#first_cancel_icon,
#second_cancel_icon,
#third_cancel_icon {
  position: relative;
  top: 0;
  left: 0;
  cursor: pointer;
  height: 20px;
}

code-des .input_heading input {
  width: 100%;
}

input:focus-visible {
  outline: none;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 150px;
  max-height: 83vh;
  overflow-y: overlay;
  z-index: 0;
}

span.code-des {
  text-wrap: nowrap;
}

.search-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 50px;
  background-color: #fff;
  padding: 20px 15px;
  z-index: 1;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.more-filter {
  display: none;
  margin: auto 0px;
}

.left_side,
.search_res {
  display: none;
}

.card_cheapest_section {
  border-radius: 0.25rem 0rem 0rem 0.25rem;
}

.card.card_fastest_section {
  border-radius: 0rem 0.25rem 0.25rem 0rem;
}

.card.card_more {
  display: none;
}

.main_content.res_containt .block:nth-child(1) {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.main_content.res_containt .card-img {
  width: auto;
  margin-right: 10px;
}

div#first_cancel .inline-text,
div#second_cancel .inline-text,
div#third_cancel .inline-text,
.hide-div .inline-text {
  color: #000;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  text-align: center;
}

.box-header .box-heading {
  font-size: 1rem;
  color: #212529;
  font-weight: 100;
}

h1.h1-inner.mb-0 {
  color: #2f4858;
  font-size: 25px;
}

.w3-animate-left {
  position: relative;
  animation: animateleft 0.4s;
}

@keyframes animateleft {
  from {
    left: -10px;
    opacity: 0;
  }

  to {
    left: 0;
    opacity: 1;
  }
}

.w3-animate-top {
  animation: animatetop 0.4s;
}

@keyframes animatetop {
  from {
    top: -200px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

.w3-animate-zoom {
  animation: animatezoom 0.6s;
}

@keyframes animatezoom {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

.w3-animate-fading {
  animation: fading 2s;
}

@keyframes fading {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

.tnb-fixed.navbar {
  position: fixed;
  top: 0;
  transition: background-color 0.3s ease-in-out;
  width: 100%;
  z-index: 1000;
}

.tnb-fixed.sticky {
  background-color: #fff;
}

h3.confirm-heading {
  text-align: left;
  padding: 0px 20px;
}

.confirmation-box {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 1rem;
  border-radius: 6px;
}

.confirm-box {
  width: 100%;
}

li.box-content {
  list-style: none;
}

.login_checkout {
  width: 150px;
}

.text-left1 .form-label {
  color: #212529;
  font-size: 1rem;
  font-weight: normal;
}

.checkout-col .card-content p {
  font-size: 0.9rem;
  color: #212529;
}

.content_body .text-left,
.text-important {
  font-size: 0.9rem;
}

.card-content .flight_text {
  font-size: 0.9rem;
  text-align: left;
  text-transform: capitalize;
}

a.view-flight-details {
  color: #ed3237;
  font-size: 0.9rem;
}

a.view-flight-details:hover {
  text-decoration: none;
}

h3.box_heading {
  font-size: 1.2rem;
  font-weight: 600;
}

h3.checkout-heading {
  font-size: 16px;
  font-weight: 600;
}

/* stepper -css */
.step.complete {
  display: flex;
  grid-column-gap: 20px;
  margin-bottom: 20px;
}

.step .circle {
  background-color: white;
  border: 3px solid gray;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  display: inline-block;
}

.step .line {
  top: 20px;
  left: 10px;
  height: 100%;
  position: absolute;
  border-left: 3px solid gray;
}

.v-stepper {
  position: relative;
}

.modal-content.site-modal.site_modal {
  width: 50%;
  display: flex;
  text-align: center;
  justify-content: center;
  margin: auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: #fff;
}

h3.box_heading_check {
  color: #2f4858;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
}

.overlay {
  background: #000;
  opacity: 0.7;
  position: absolute;
  height: 100%;
  width: 100%;
}

button.btn.scrollTop {
  position: fixed;
  bottom: 120px;
  right: 10px;
  background-color: #ed3237;
  color: #fff;
  opacity: 0.8;
  height: 50px;
  visibility: hidden;
  width: 50px;
  z-index: 2;
  opacity: 1;
  border-radius: 50%;
  -webkit-transform: translateY(-40px);
  -webkit-transition: all 0.5s ease;
}

button.btn.scrollTop.show {
  -webkit-transform: translateY(0px);
  opacity: 1;
  visibility: visible;
}

button.btn.scrollTop:hover {
  opacity: 1;
}

.tab_heading:hover {
  border-bottom: 3px solid #ed3237;
  padding-bottom: 5px;
}

.tab_heading {
  font-size: 15px;
  color: #000;
  cursor: pointer;
}

.active .tab_heading {
  border-bottom: 3px solid #ed3237;
  padding-bottom: 5px;
}

th.active {
  text-align: center;
  background: #e5e5e5;
  color: #ed3237;
}

td.active {
  text-align: left;
  background: #e5e5e5;
  color: #ed3237;
}

table.table.mb-5.table-hover.table-bordered td {
  text-align: left;
}

li.list-itme {
  color: #000;
  padding-bottom: 10px;
}

.short-bagger {
  padding: 0.5rem 1rem;
}

.desc-section {
  background: rgba(211, 47, 47, 0.1);
  padding: 0.5rem 1rem;
  color: #d32f2f;
}

.col-sm-8 .list-group {
  padding: 0.5rem 1rem;
}

h1.page_not_found {
  font-size: 150px;
}

a.tel-contact {
  color: #fff;
  font-weight: 600;
}

a.tel-contact:hover {
  text-decoration: none;
}

a.tel-contact,
a.tel-contact span,
a.tel-contact svg {
  color: #2f4858;
  fill: #2f4858;
}

.tnb-fixed.sticky .tel-contact {
  color: #2f4858;
}

.tnb-fixed.sticky .tel-contact svg {
  fill: #2f4858 !important;
}

.footer-contact .tel-contact {
  color: #ed3237;
  border: 1px solid #ed3237;
  border-radius: 4px;
  padding: 8px 10px;
  background-color: #fff;
}

.footer-contact .tel-contact svg {
  fill: #ed3237;
}

.footer-contact .tel-contact:hover {
  background-color: #ed3237;
  color: #fff;
}

.footer-contact .tel-contact:hover svg {
  fill: #fff;
}

.inline-form2.rounded-form .inline-text {
  width: 20%;
}

.inline-form2.oneWay-form .inline-text {
  width: 25%;
}

img.go-now-image {
  height: 92%;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
  margin-top: 10px;
}

.footer-div {
  position: absolute;
  bottom: 29px;
  text-align: center;
  margin: auto;
  width: 80%;
  right: 28px;
}

.footer-div .btn-login {
  display: block;
  width: 100%;
  text-align: center;
  margin: auto;
}

p.edit_lead {
  color: #212529;
}

p.card-text.text_overflow {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

span.select-option.option-res {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.3;
  padding: 4px;
  position: absolute;
  top: 10px;
  font-weight: 700;
  text-wrap: wrap;
}

.owl-carousel.owl-carousel.owl-theme.hotel-carousel.owl-hotel-room .owl-nav {
  top: 50%;
}

.carousel-owl .owl-theme.photo-theme .owl-nav {
  top: 50%;
}

.carousel-owl .owl-theme.photo-theme .owl-nav {
  margin-top: 0;
  margin-right: 0;
  padding-left: 25px;
}

.carousel-owl .owl-theme.photo-theme .owl-nav [class*="owl-"] {
  background-color: #fff;
  border: 1px solid #fff !important;
  color: #ed3237;
}

.carousel-owl .owl-theme.photo-theme .owl-nav [class*="owl-"]:hover {
  background-color: #ed3237;
  border: 1px solid #ed3237 !important;
  color: #fff;
}

.tooltip .tooltiptext {
  width: 100%;
}

label.form-label.label_text.tooltip {
  width: 100%;
}

.whats-app-icon {
  position: fixed;
  bottom: 50px;
  right: 10px;
  z-index: 3;
  cursor: pointer;
}

.whats-app-icon img {
  width: 54px;
}

.main_content.res_main_block.align-self-start {
  align-items: self-start;
}

.confirm-box .main-box:last-child .hotel-arrow-icon img {
  display: none !important;
}

.overview .main_content .block {
  width: 33%;
  padding: 0px 10px;
  justify-content: space-between;
}

.search-desktop .right-div {
  right: 0;
  top: 0px;
}

.res-desktop {
  right: 0;
  position: relative;
  top: 0;
  display: none;
}

.res-desktop input {
  width: auto;
}

.left-div.res-admin {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 0px;
}

.res-fliter-image {
  display: none;
}
.filter-admin img {
  height: 20px;
}
button.btn-close.close {
  background-color: #fff;
  opacity: 1;
}
ul.dropdown-menu.passengers-menu.show {
  height: 210px;
  overflow: overlay;
}
.add-city-div .inline-form2 .inline-text img {
  position: absolute;
  left: 10px;
  top: 23px;
}
.left-div.res-admin .h1-inner {
  display: none;
}
.filter-admin {
  cursor: pointer;
}
.modal-footer.admin-filter-btn {
  justify-content: start;
  padding: 1rem 0rem;
}
.form-check.admin-dash {
  display: flex;
  align-items: baseline;
  gap: 7px;
  padding-bottom: 5px;
}
label.form-check-label.admin-label {
  font-size: 20px;
}
.select-flight {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  background-color: #fff;
}
.city-name .card-text {
  font-size: 13px;
}
.select-tab .tab-bold,
.select-tab .tab-bold.tab-active {
  border: none;
  border-radius: 0px;
  box-shadow: 0 0 10px hsla(240, 5%, 41%, 0.5);
}
.select-tab-container .select-tab:first-child .tab-bold {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.select-tab-container .select-tab:last-child .tab-bold {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.offcanvas.offcanvas-bottom.show {
  height: 60vh;
}
.tabbing-bar.res_tabbing {
  margin-bottom: 15px;
}
p.box-list {
  font-size: 15px;
}
.res_containt .card-content.content_body .card-text {
  width: 115px;
}
.card-content .h3-bold.mb-0.res-font {
  width: 105px;
  font-size: 15px;
}
img.img-fluid.country_icon {
  height: 40px;
  padding: 0px 5px;
  margin: auto;
  width: 45px;
}
.userProfilePicture {
  object-fit: fill;
  height: 100%;
}
.autoCompleted {
  margin-top: -10px;
}
.sorry_emoji {
  max-width: 25%;
}
.inline-form2 .inline-text .top-row img {
  position: initial;
}
.block.res_icon_block {
  display: contents;
}
.waring_images {
  width: 100%;
}
img.image_arrow {
  transform: rotate(90deg);
}
img.image_arrow.active {
  transform: rotate(180deg);
}
li.py-1.listing_item {
  padding-left: 20px;
}
img.image_arrow {
  transform: rotate(90deg);
}
img.image_arrow.active {
  transform: rotate(180deg);
}
li.py-1.listing_item {
  padding-left: 20px;
}
.airline_text {
  padding: 5px 10px;
  border-radius: 6px;
  margin: 5px 3px;
}
.accordion-item > div {
  padding: 5px 15px;
}
.w-100.px-3 .main-box {
  border: none;
}
.btn-color {
  background-color: #0e1c36;
  color: #fff;
}

.profile-image-pic {
  height: 200px;
  width: 200px;
  object-fit: cover;
}
.cardbody-color {
  background-color: #ebf2fa;
}

.add-city-div .inline-text {
  width: 33.33%;
  max-width: 32%;
  /* overflow: auto; */
}
.input_heading input {
  width: 100%;
}
span.remove-flight {
  display: none;
}

div#one-room .code_heading {
  height: 31px;
}
.accordion {
  cursor: pointer;
}
.col-sm-6.px-0.panel-container.panel_container {
  height: auto;
}
@media screen and (max-width: 992px) {
  .hero-section-top {
    margin-bottom: 0px;
    margin-top: 120px;
  }
  p.fare-rule-text {
    max-height: 100%;
    margin: 10px 0px;
    overflow: auto;
  }

  .res_containt .card-content.content_body .card-text {
    width: auto;
  }
  .card-content .h3-bold.mb-0.res-font {
    width: auto;
  }
  .inline-text1 {
    margin-bottom: 10px;
  }
  .search-sticky {
    top: 110px;
  }
  span.remove-flight {
    display: block;
  }
  span.flight-cross-icon {
    display: none;
  }
  .add-city-div .inline-text {
    width: 100%;
    max-width: 100%;
  }

  .modal.fade.left-flight-siderbar.show,
  .modal.fade.left-flight-siderbar .modal-dialog {
    padding: 0px;
    margin: auto;
  }

  .res-admin {
    margin-top: 25px;
  }

  .row_content .block {
    margin-bottom: 0px;
  }

  main {
    padding-top: 100px;
  }

  .block .row.body-container {
    padding: 0px 15px;
  }

  .res_hide {
    display: none;
  }

  .res_show {
    display: block;
  }

  .inline-form2.hotel-tab .inline-text,
  .inline-form2.hotel-tab .inline-text1 {
    width: 100%;
  }

  span.select-option.option-res {
    font-size: 16px;
    color: rgb(0, 0, 0);
    line-height: 1.3;
    padding: 4px;
    position: absolute;
    top: 4px;
    text-wrap: wrap;
  }

  .res_show {
    display: block;
  }

  a.tel-contact {
    font-size: 15px;
  }

  .card-body.res_show .res-left {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: #fff;
    height: 100%;
    padding: 1rem;
  }

  .carousel-owl .owl-theme .owl-nav [class*="owl-"] {
    color: #fff;
    font-size: 24px;
    padding: 0 !important;
    font-weight: 700;
    transition: all 0.3s ease-in-out 0s;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
  }

  .owl-carousel .owl-nav button {
    border: 1px solid white !important;
  }

  .responsive-owl .owl-nav {
    display: flex !important;
    justify-content: space-between;
    position: absolute;
  }

  .carousel-owl .owl-theme .owl-nav {
    padding-left: 27px;
  }

  .inline-form2.rounded-form .inline-text {
    width: 100%;
  }

  .inline-form2.oneWay-form .inline-text {
    width: 100%;
  }

  .modal-content.site-modal.site_modal {
    width: 100%;
  }

  h3.box_heading {
    font-size: 16px;
  }

  .search_res {
    display: block;
  }

  button.search_2,
  button#search_record {
    display: none;
  }

  .main_content .block .card-img {
    margin-right: 10px;
  }

  .bottom_text {
    position: relative;
  }

  .line_container {
    display: none;
  }

  table.table th {
    display: none;
  }

  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  th,
  td {
    width: 100%;
    display: block;
    text-align: end;
  }

  table.table tbody tr {
    margin-bottom: 30px;
    display: block;
  }

  .h1-inner {
    font-size: 20px;
    line-height: 45px;
  }

  .box-ind {
    width: 100%;
  }

  a.tel-contact span,
  a.tel-contact,
  a.tel-contact svg {
    color: #2f4858 !important;
    fill: #2f4858 !important;
  }

  .payment_btn {
    padding-bottom: 20px;
  }

  .row_container {
    flex-wrap: inherit;
  }

  .payment_div::before {
    height: 50%;
  }

  .checkout-col {
    width: 50%;
  }

  ul.ul-navbar {
    display: block;
    text-align: center;
    margin: auto;
  }

  li.li-list {
    text-align: center;
    padding-bottom: 10px;
  }

  a.btn.outline-link {
    margin: auto;
  }

  div#city_col {
    flex-direction: row;
  }

  div#first_depart,
  div#second_depart {
    margin-top: 15px;
  }

  .res_form .btn {
    display: block;
    text-align: center;
    margin: auto;
    margin-bottom: 15px !important;
  }

  form.dropdown.res_form {
    display: block !important;
    width: 100%;
    text-align: center;
  }

  ul.dropdown-menu.res_profile.show {
    position: relative;
  }

  .dropdown-item.res_item {
    text-align: center;
  }

  .w3-animate-top1 {
    animation: animatetop 0.4s;
  }

  @keyframes animatetop {
    from {
      top: -20px;
      opacity: 0;
    }

    to {
      top: 0;
      opacity: 1;
    }
  }
}

@media screen and (max-width: 500px) and (min-width: 300px) {
  .card_checkout .main_content {
    justify-content: flex-start;
    gap: 25px;
  }
  .inline-form .form-check {
    margin-right: 10px;
    margin-top: 0px;
  }
  .py-3.block_main_container span {
    font-size: 15px;
  }
  .py-3.block_main_container .h3-bold {
    font-size: 15px;
  }
  .overview p.card-text.text-end:last-child {
    height: 50px;
  }
  .overview .main_content .block .card-content .card-text:last-child {
    height: 115px;
  }
  .overview .main_content .block .card-content .card-text:nth-child(3) {
    height: 20px;
  }
}

@media screen and (max-width: 668px) and (min-width: 300px) {
  .main_content.res_containt .block:nth-child(1) {
    width: 100%;
    display: flex;
    gap: 5px;
    justify-content: flex-start;
    align-items: center;
  }
  h3.name-title {
    font-size: 16px;
  }
  .main_content.res-checkout {
    justify-content: flex-start;
  }
  .card-content.res-checkout {
    margin-left: 30px;
  }
  .res-fliter-image {
    display: block;
  }
  .left-div.desktop-view {
    display: none;
  }
  .left-div.res-admin .h1-inner {
    display: block;
  }
  .res-fliter-image img {
    height: 20px;
  }

  .res_add {
    display: block;
  }

  .search-desktop {
    display: none;
  }

  .filter-admin {
    display: none;
  }

  .res-desktop .right-div {
    right: 0;
    top: -15px;
  }

  .position-relative .right-div {
    bottom: 0px;
    top: auto;
  }

  .main_content.res_containt .block {
    width: 33.33%;
  }

  .main_content.res_containt .card-content.content_body {
    width: 33.33%;
    padding: 0px 10px;
  }

  .res-desktop {
    display: block;
  }

  .res-admin .add_icon {
    display: none !important;
  }
}

@media screen and (max-width: 600px) and (min-width: 300px) {
  .footer-contact .tel-contact {
    font-size: 12px;
  }
  .city-name p,
  .city-name .card-text {
    font-size: 12px;
  }
  .tab-bold.tab-active,
  .tab-bold {
    font-size: 13px;
  }
  p.card-text.res-font {
    font-size: 14px;
  }

  .main_content.res_containt .card-img {
    width: auto;
    margin-right: 10px;
  }

  .select-tab {
    flex-wrap: wrap;
  }

  .row_photo .col-sm-2 {
    width: 33.33%;
  }

  .add_icon {
    position: relative;
    text-align: left;
    padding: 0px 5px !important;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 400px) and (min-width: 300px) {
  .footer-row .col-sm-3 {
    width: 100%;
  }
  .h1-inner {
    font-size: 18px;
  }
  .main_content.res_main_block .card_block.res_block .img-fluid {
    aspect-ratio: 14/11;
  }
  .main_content.res_main_block .card_block.res_block {
    width: 50%;
    margin-bottom: 20px;
    justify-content: flex-start;
  }

  button.nav-link.responsivetab {
    font-size: 12px;
  }

  .login_section .block {
    justify-content: center;
  }

  .col-sm-6.col-md-6.checkout-col {
    width: 100%;
  }

  .col-md-6.col-sm-6 .payment-method {
    padding-top: 20px;
  }

  .more-filter {
    display: block;
  }

  .left_section {
    display: none;
  }

  .card.card_more {
    display: block;
  }

  .col-sm-4 .confirmation-box {
    margin-bottom: 22px;
  }
}

@media screen and (max-width: 450px) and (min-width: 300px) {
  .block_btn .date_icon {
    display: block;
    width: 100%;
  }
  .left-div.res-admin {
    gap: 5px;
  }
  h3.h3-bold.res-font {
    font-size: 14px;
  }
  .overview .h3-bold.res-font.mb-0 {
    width: 65px;
  }
}

@media only screen and (max-width: 767px) {
  main.container.main-container {
    padding-top: 0px;
  }
}
@media screen and (max-width: 767px) and (min-width: 600px) {
  .main_content.res_main_block .card_block.res_block {
    width: 25%;
  }
}

@media screen and (max-width: 900px) and (min-width: 767px) {
  .main_content.res_main_block .card_block.res_block {
    width: 20%;
  }
  .tabbing-bar {
    top: 30px;
  }
  .tabbing-bar.res_tabbing {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 600px) and (min-width: 400px) {
  .footer-row .col-sm-3 {
    width: 50%;
  }
  .main_content.res_main_block .card_block.res_block .img-fluid {
    aspect-ratio: 14/13;
  }
  .main_content.res_main_block .card_block.res_block {
    width: 33%;
  }

  .col-md-6.col-sm-6 .payment-method {
    padding-top: 20px;
  }

  .left_section {
    display: none;
  }

  .card.card_more {
    border-radius: 0rem 0.25rem 0.25rem 0rem;
    height: 48px;
  }

  .card.card_fastest_section {
    border-radius: 0;
  }
}

@media screen and (max-width: 992px) and (min-width: 768px) {
  .tabbing-bar.hotel-bar {
    top: 30px;
  }
  .tabbing-bar.res_tabbing {
    top: 30px;
  }
}
@media screen and (max-width: 768px) and (min-width: 300px) {
  .tabbing-bar.hotel-bar {
    top: 0;
  }
  .modal-footer.admin-filter-btn {
    justify-content: space-between;
  }
  .main_div::before {
    height: 39%;
  }
  button.change-flight-btn {
    font-size: 14px;
  }
  .hero-section {
    height: auto;
    padding-top: 1px;
  }
}

@media screen and (max-width: 900px) and (min-width: 400px) {
  .card.card_more {
    border-radius: 0rem 0.25rem 0.25rem 0rem;
    height: 48px;
    align-items: self-start;
    display: flex;
  }

  .col-sm-4 .confirmation-box {
    margin-bottom: 22px;
  }

  .card.card_fastest_section {
    border-radius: 0;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 100%;
  }

  .left_section {
    display: none;
  }

  .more-filter {
    display: block;
  }

  .col-sm-4 {
    width: 100%;
  }

  .col-sm-8 {
    width: 100%;
  }

  .col-sm-6.col-md-6 {
    width: 100%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 100%;
  }

  .col-sm-3.col-md-3.pb-4.checkout-col {
    width: 50%;
  }

  .left-side {
    font-size: 10px;
  }

  .card_cheapest_section,
  .card_fastest_section {
    font-size: 14px;
  }

  h3,
  h5 {
    font-size: 15px;
  }

  button.btn.btn-select {
    font-size: 13px;
  }

  .btn-login,
  .lg-btn {
    font-size: 14px;
  }

  .select-dropdown .dropdown-toggle {
    font-size: 14px;
  }

  label.form-check-label {
    font-size: 14px;
  }

  .header_section .h3-bold {
    font-size: 15px;
  }

  .h3-bold {
    font-size: 15px;
  }

  span.hading_check {
    font-size: 12px;
  }

  .form-check span {
    font-size: 12px;
  }

  .block > ul > li span {
    font-size: 12px;
  }

  .header_section span {
    font-size: 13px;
  }

  .content_body p {
    font-size: 14px;
  }

  .row_container::after {
    left: 70px;
  }

  .main_div::before {
    left: 70px;
  }

  .payment_container::after {
    left: 50px;
  }

  .payment_div::before {
    left: 50px;
  }

  .hero-section {
    height: auto;
    padding-top: 1px;
  }
}

@media screen and (max-width: 933px) and (min-width: 900px) {
  .main_div::before {
    height: 39%;
  }
}

@media screen and (max-width: 900px) and (min-width: 600px) {
  .footer-row .col-sm-3 {
    width: 25%;
  }

  .col-sm-5,
  .col-sm-7 {
    width: 100%;
  }

  .col-md-6.col-sm-6 .payment-method {
    padding-top: 20px;
  }
}

@media screen and (max-width: 900px) and (min-width: 300px) {
  .login_section.res_section {
    display: block;
  }
  .card-content p {
    font-size: 13px;
  }
  .card-content h3.block_heading {
    font-size: 13px;
  }
  svg.text-success {
    width: 35px;
  }
  h3.payment_heading {
    font-size: 10px;
  }
  p.payment-sub {
    font-size: 8px;
  }
  .md-step-title p {
    font-size: 10px;
  }
  .md-step-title .h1-inner {
    font-size: 14px;
  }

  .card-content .mb-4.text-left1 {
    margin-bottom: 0px !important;
  }
  .main_content.res_containt .block .card-content .card-text,
  .main_content.res_containt .block .card-content .h3-bold {
    text-align: center;
  }
  .carousel-owl .owl-theme.photo-theme .owl-nav {
    display: flex;
    padding: 0px 20px 0px 28px;
  }

  .main_content.icon-content {
    gap: 20px;
  }

  .main_content.res_main_block .card_block.res_block {
    margin-bottom: 20px;
    justify-content: flex-start;
  }

  .inline-text.select-night {
    padding: 0;
  }

  .main_content.res_main_block {
    justify-content: flex-start;
    gap: 0;
  }

  .login_section {
    display: none;
  }

  .sticky-top {
    position: relative;
    top: 0;
    overflow: hidden;
    max-height: fit-content;
  }

  .payment_btn {
    text-align: center;
  }

  .res-hide-flight-tap {
    display: none;
  }
}

@media screen and (max-width: 1240px) and (min-width: 900px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }

  .form-contact {
    display: block;
  }
  .add-city-div .inline-text {
    width: 100%;
    max-width: 100%;
  }
  span.remove-flight {
    display: block;
  }
  span.flight-cross-icon {
    display: none;
  }
  .inline-form2.rounded-form .inline-text {
    width: 100%;
  }
  .md-stepper-horizontal .md-step {
    padding: 24px 0px;
  }
  .inline-form2.oneWay-form .inline-text {
    width: 100%;
  }

  .inline-form2.hotel-tab .inline-text,
  .inline-form2.hotel-tab .inline-text1 {
    width: 100%;
  }

  .hero-section {
    height: auto;
    padding-top: 1px;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  .inline-form2 {
    flex-wrap: wrap;
    row-gap: 10px;
  }

  .login_section .block {
    justify-content: center;
  }
}

@media screen and (max-width: 992px) and (min-width: 600px) {
  .col-sm-5.res_widht,
  .card-body.res_show.res_widht {
    width: 50%;
  }
  .main_content.res_main_block .card_block.res_block .img-fluid {
    aspect-ratio: 14/14;
  }
}

@media screen and (max-width: 375px) and (min-width: 300px) {
  button.nav-link.responsivetab {
    font-size: 11px;
  }
  .left-div.res-admin {
    gap: 2px;
  }
}
