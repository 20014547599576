.heading{
        margin-bottom: 20px;
    color: #000000;
    font-weight: 700;
    text-transform: capitalize;
    font-size: 30px;
    margin-top: 40px;
}

.destination-list{}

.destination-list .question{}

.destination-list .question p{}

.destination-list .question p span{
        font-weight: 700;

}

.destination-list .answer{
        background: #F5F5F5;
    padding: 15px;
}

.destination-list .answer p{}

.destination-list .answer p span{
    font-weight: 700;
}

.destination-list .answer ul{
        padding-left: 50px;
}

.destination-list .answer ul li{}

.destination-list .answer ul li a{
        text-decoration: underline;
    text-decoration-color: #000000;
}

.destination-list .answer ul li a p.links{
        margin-bottom: 9px;
    color: #000000;
}

.more-links{
    margin: 50px 0;
}

.more-links .more-heading{}

.more-links .more-heading h4{
    color: #000000;
    font-weight: 700;
}

.more-links .more-heading h4 a{
    display: inline;
    text-decoration: none;
    color: #000000;
    text-transform: capitalize;
    font-weight: 700;
}

.more-links .more-heading .link-list{
    display: flex;
}

.more-links .more-heading .link-list ul{}

.more-links .more-heading .link-list ul li{
    display: flex;
    float: left;
    padding-right: 22px;
    padding-left: 22px;
    border-right: 1px solid #cccccc;
}

.more-links .more-heading .link-list ul li:first-child{
    padding-right: 22px;
    padding-left: 0;
}

.more-links .more-heading .link-list ul li:last-child{
    padding-right: 0;
    border-right: none;
}

.more-links .more-heading .link-list ul li a{
}

.more-links .more-heading .link-list ul li a:hover{
    text-decoration-color: #000000
}

.more-links .more-heading .link-list ul li a p{
    color: #000000;
    margin-bottom: 0;
}