.des-filter {
  max-width: 320px;
  padding: 10px 20px;
  background: #ed3237;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
}

.des-filter .filter-cal-main {
}

.des-filter .filter-cal-main h3 {
  color: #ffffff;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 12px;
}

.des-filter .filter-cal {
  display: flex;
  border: 1px solid #cccccc;
  padding: 10px 10px;
  background: #ffffff;
  border-radius: 7px;
  align-items: center;
}

.des-filter .filter-cal svg {
  font-size: 22px;
  margin-right: 10px;
}

.des-filter .filter-cal input {
  border: none;
}

.calendar-container .rdrCalendarWrapper {
  position: absolute;
  border: 1px solid #cccccc;
  border-radius: 10px;
}

.des-filter-rest {
  max-width: 320px;
  padding: 10px 20px;
  background: #ffffff;
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
}

.des-filter-rest .filter-acco-section {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 20px;
}

.des-filter-rest h3 {
  font-size: 18px;
  color: #000000;
  padding-top: 10px;
}

.des-filter-rest h3.head-two {
  margin-top: 10px;
}

.des-filter-rest
  .filter-acco-section
  .css-1elwnq4-MuiPaper-root-MuiAccordion-root:last-of-type {
  box-shadow: none;
}

.des-filter-rest
  .filter-acco-section
  .css-1elwnq4-MuiPaper-root-MuiAccordion-root:first-of-type {
  box-shadow: none;
  position: static;
}

.des-filter-rest
  .filter-acco-section
  .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  padding: 0 16px 0 0;
  min-height: 40px;
  font-size: 15px;
  box-shadow: none;
}

.des-filter-rest
  .filter-acco-section
  .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  margin: 10px 0;
}

.des-filter-rest .filter-acco-section .css-15v22id-MuiAccordionDetails-root {
  font-size: 14px;
}

.filter-acco-section .css-1elwnq4-MuiPaper-root-MuiAccordion-root:before{
  position: unset;
}

.filter-time-section {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 20px;
}

.filter-time-section.last{
    border-bottom: none;
    padding-bottom: 0;
}

.filter-time-section .filter-time {
  margin: 12px 0;
}

.filter-time-section .filter-section {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin-top: 15px;
}

.filter-time-section .filter-section input[type="checkbox"] {
  width: 20px;
  height: 20px;
  cursor: pointer;
  border: 1px solid #cccccc;
  border-radius: 3px;
  margin-top: 2px;
}

.filter-time-section .filter-section .filter-box {
  display: flex;
  flex-direction: column;
}

.filter-time-section .filter-section .filter-box h3 {
  padding-top: 0;
  font-size: 17px;
}

.filter-time-section .filter-section .filter-box p {
  margin-bottom: 0;
  font-size: 15px;
}

.filter-time-section .filter-section .filter-box p.title-two {
  font-size: 17px;
}

.filter-time-section button {
  margin-top: 15px;
  border: none;
  background: none;
  padding: 0;
  font-size: 13px;
  font-weight: 700;
}

.filter-time-section .css-6g7qdi{
    width: auto;
}

.filter-time-section .range-section{
        margin-top: 35px;
}

.filter-time-section .range-labels{
    display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  position: absolute;
  width: 100%;
  top: -20px;
}

.filter-time-section .range-value {
  font-size: 15px;
}

.filter-time-section .range-value.left {
  left: 0;
}

.filter-time-section .range-value.right {
  right: 0;
}

/* Ratings */
.filter-time-section .ratings-section{}

.ratings-section .rating{
    display: flex;
    align-items: center;
    gap: 20px;
        margin: 12px 0;
}

.ratings-section .rating input[type="radio"]{
        width: 20px;
    height: 20px;
    cursor: pointer;
    border: 1px solid #cccccc;
    border-radius: 3px;
    margin-top: 2px;
}

.ratings-section .rating .rating-stars{
    display: flex;
    align-items: center;
    gap: 5px;
}

.ratings-section .rating .rating-stars svg{
    color: #FED141;
        font-size: 20px;
}

.ratings-section .rating p.rating-text{
    margin-bottom: 0;
}
