y.card-wrapper {
  border: 1px solid #cccccc;
  border-radius: 7px;
  margin-bottom: 15px;
}

.card-wrapper a {
  display: block;
  color: #000000;
  text-decoration: none;
}

.card-wrapper a:hover,
.card-wrapper a:focus,
.card-wrapper a:active {
  text-decoration: none;
}

.card-wrapper .card-inner {
  padding: 16px;
  display: flex;
  align-items: start;
  gap: 20px;
}

.card-wrapper .card-sec-one {
  position: relative;
}

.card-wrapper .card-sec-one .image-sec {
  position: relative;
}

.card-wrapper .card-sec-one .image-sec img {
  max-width: 360px;
  border-radius: 7px;
}

.card-wrapper .card-sec-one .card-fav {
  position: absolute;
  top: 20px;
  right: 20px;
  background: #ffffff;
  padding: 8px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

.card-wrapper .card-sec-one .card-fav svg {
  color: #000000;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card-wrapper .card-sec-one .card-sell-out {
  position: absolute;
  top: 20px;
  left: 20px;
  max-width: max-content;
  text-align: center;
  background: #ed3237;
  padding: 3px 10px;
  border-radius: 10px;
}

.card-wrapper .card-sec-one .card-sell-out p {
  margin-bottom: 0;
  color: #ffffff;
  font-weight: 700;
}

.card-wrapper .card-sec-two {
}

.card-wrapper .card-sec-two h4 {
  color: #000000;
  font-weight: bold;
  font-size: 20px;
}

.card-wrapper .card-sec-two .card-ratings {
  display: flex;
  align-items: center;
}

.card-wrapper .card-sec-two .rating {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.card-wrapper .card-sec-two .rating svg {
  color: #fed141;
  font-size: 16px;
  margin-right: 6px;
}

.card-wrapper .card-sec-two p {
  margin-bottom: 0;
}

.card-wrapper .card-sec-two .card-summary {
  margin: 10px 0;
}

.card-wrapper .card-sec-two .card-summary p {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 21px;
}

.card-wrapper .card-sec-two .card-extras {
}

.card-wrapper .card-sec-two .card-ex-con {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  margin: 5px 0;
}

.card-wrapper .card-sec-two .card-ex-con svg {
  font-size: 17px;
}

.card-wrapper .card-sec-two .card-ex-con p {
  font-size: 14px;
  line-height: 21px;
}

.card-wrapper .card-sec-three {
  display: flex;
  flex-direction: column;
}

.card-wrapper .card-sec-three p.normal {
  margin-bottom: 0;
  text-align: right;
  font-size: 14px;
  line-height: 21px;
}

.card-wrapper .card-sec-three p.prize {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 21px;
  line-height: 26px;
  text-align: right;
}

.pagination-section {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    padding-bottom: 30px;
    border-bottom: 1px solid #cccccc;
}

.pagination-section
.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-color: #ed3237;
    color: #ffffff;
}
