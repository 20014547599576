.des-res-component{
    margin-left: 25px;
}

.des-res-summary{}

.des-res-summary p{
    text-align: justify;
}

.des-res-filters{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0 30px 0;
}
 
.des-res-filters .filter-sec-one{}

.des-res-filters .filter-sec-one p{
    margin-bottom: 0;
}

.des-res-filters .filter-sec-two{}

.des-res-filters .filter-sec-two .filter-dropdown{}

.des-res-cards{}