@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800&display=swap");
html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  color: #878787;
  font-family: "Open Sans", sans-serif;
  font-weight: 100;
  padding: 0px;
  margin: 0px;
}

.card-tempa-bay {
  background: #fff;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 7px 29px rgba(100, 100, 111, 0.2);
}

.tabbing-bar {
  max-width: 100%;
  margin: auto;
  padding-right: 10px;
  position: relative;
  z-index: 2;
}

.tabbing-bar .tab-content {
  background: #fff;
  padding: 20px;
  border-radius: 0px 6px 6px 6px;
  box-shadow: 0px 0px 10px rgba(100, 100, 111, 0.2);
  margin-top: 0px;
  z-index: 1;
  position: relative;
}

.select-dropdown .dropdown-menu {
  padding: 0.5rem;
  font-size: 14px;
  min-width: 13rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.select-dropdown .dropdown-toggle {
  color: #101010;
}

.select-dropdown a.dropdown-toggle:hover {
  color: #101010;
  text-decoration: none;
}

.select-dropdown .form-check .form-check-input {
  float: right;
  border: none;
}

.select-dropdown .form-check-input:checked[type="radio"] {
  background: url("../../../assets/images/check.png") no-repeat left top;
}

.select-dropdown .form-check-input:active {
  filter: none;
}

.dropdown-menu {
  color: #707070;
}

.select-dropdown .form-check {
  padding-left: 0.5em;
}

.select-dropdown .form-check-input:checked + label {
  color: #2f4858;
}

.select-dropdown {
  margin-right: 30px;
}

.select-dropdown label {
  cursor: pointer;
}

.select-dropdown .dropdown-toggle::after {
  content: "";
  background: url("../../../assets/images/CaretDown.png") no-repeat left top;
  border: none;
  width: 16px;
  height: 16px;
  position: relative;
  top: 6px;
}

.top-row {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #2f4858;
}

.passengers-menu li {
  font-size: 12px;
}

.form-control {
  border-radius: 8px;
  border: 1px solid #d9d9d9;
}

.destination {
  width: 390px;
  max-width: 100%;
}

.card3 {
  text-align: center;
}

.card3 p {
  font-size: 14px;
  max-width: 270px;
  margin: auto;
}

.icon-box {
  width: 80px;
  height: 80px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ed3237;
  border-radius: 50%;
  margin-bottom: 20px;
}

h1 {
  color: #2f4858;
  font-size: 48px;
  line-height: 65.37px;
}

h2 {
  color: #2f4858;
  font-size: 36px;
  font-weight: 700;
}

h3 {
  color: #2f4858;
  font-size: 20px;
  font-weight: 700;
}

.p1 {
  max-width: 608px;
  margin: auto;
  text-align: center;
  margin-bottom: 50px;
}

.talk-to-agents {
  color: #fff;
  position: relative;
  background: url("https://d3c46yygxatk0.cloudfront.net/cdn/images/banner/talk-to-agent.jpg")
    no-repeat left top;
  background-size: cover;
  margin-top: 120px;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.talk-to-agents h3 {
  color: #fff;
  font-size: 36px;
}

.talk-to-agents p {
  max-width: 345px;
  margin-left: auto;
  margin-right: auto;
}

.talk-to-agents .container {
  position: relative;
  z-index: 1;
}

.talk-to-agents::after {
  content: "";
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

a,
a:focus,
a:hover,
.btn {
  outline: none;
  text-decoration: none;
  transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
}

a {
  color: #3987c8;
}

a:hover {
  text-decoration: underline;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #3987c8;
}

.linkText {
  color: #3987c8;
  position: relative;
  z-index: 1;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus {
  background: #2a6eb4;
  border: 1px solid #2a6eb4;
}

.outline-btn {
  border: 1px solid #ed3237;
  background: #fff;
  color: #ed3237;
  width: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.outline-btn:hover,
.outline-btn:focus {
  background: #ed3237;
}

.small-text {
  font-size: 12px;
}

.lg-btn {
  width: 100%;
}

.btn-check:focus + .btn,
.btn:focus,
.lg-btn:hover,
.lg-btn:focus {
  box-shadow: 0 0 0 0.25rem rgba(237, 50, 55, 0.25);
}

footer {
  margin-top: 60px;
}

footer h4 {
  color: #2f4858;
  font-size: 18px;
  font-weight: 600;
}

footer ul {
  list-style: none;
}

footer li a {
  color: #878787;
  display: table;
  margin-bottom: 5px;
}

footer li a:hover {
  color: #ed3237;
  text-decoration: none;
}

.copyright {
  border-top: 1px solid rgba(16, 16, 16, 0.1);
  padding-top: 20px;
  margin-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.offer-card {
  box-shadow: 0px 0px 10px rgba(100, 100, 111, 0.2);
  border-radius: 6px;
  padding: 0px;
  margin-bottom: 15px;
  margin-top: 10px;
  margin-right: 10px;
}

.offer-col {
  display: flex;
  flex-direction: column;
}

.col-left {
  min-width: 100%;
  margin-right: 0px;
  margin-bottom: 15px;
}

.col-left img {
  border-radius: 6px;
  max-width: 100%;
  width: 100%;
}

.offer-col .col-right h4 {
  color: #2f4858;
  font-size: 18px;
  font-weight: 700;
}

.offer-col .col-right p {
  font-size: 14px;
  padding-right: 30px;
}

.link-gray {
  color: #2f4858;
}

.link-gray:hover {
  color: #ed3237;
}

.link-sm {
  color: #2f4858;
  font-size: 12px;
}

.carousel-owl {
  position: relative;
}

.site-modal {
  background: #e4f0ff;
}

.modal-head {
  font-size: 36px;
  margin-bottom: 20px;
}

.modal-body .btn-close {
  position: absolute;
  top: -30px;
  right: 0px;
  opacity: 1;
  border-radius: 6px;
}

.site-modal .modal-body {
  padding: 0px;
}

.site-modal .modal-left {
  padding: 0px;
  text-align: center;
  max-width: 304px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.site-modal img {
  max-width: 100%;
}

.text-left1 {
  text-align: left;
}

.site-modal .modal-body label {
  color: rgba(16, 16, 16, 0.8);
  font-size: 14px;
  margin-bottom: 5px;
}

.signin-text {
  position: relative;
  color: rgba(16, 16, 16, 0.8);
}

.signin-text::after {
  content: "";
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 1px;
  top: 13px;
  left: 0px;
  position: absolute;
}

.signin-text span {
  display: inline-block;
  background: #e4f0ff;
  position: relative;
  z-index: 1;
  padding-left: 5px;
  padding-right: 5px;
}

.fb-btn {
  background: #1877f2;
  font-weight: 500;
}

.fb-btn:hover {
  background: #1877f2;
  box-shadow: 0 0 0 0.25rem rgba(24, 119, 242, 0.25);
}

.fb-btn:focus {
  background: #1877f2;
  box-shadow: 0 0 0 0.25rem rgba(24, 119, 242, 0.25);
}

.fb-btn img {
  margin-right: 5px;
}

.google-btn {
  background: #fff;
  color: #2f4858;
  border: 1px solid #2f4858;
  font-weight: 500;
}

.google-btn img {
  margin-right: 5px;
}

.google-btn:hover,
.google-btn:focus {
  box-shadow: 0 0 0 0.25rem rgba(47, 72, 88, 0.25);
  background: #fff;
  color: #2f4858;
}

.modal-content {
  border: none;
}

.modal-right img {
  border-radius: 0px 4px 4px 0px;
}

.site-modal .form-control {
  font-size: 14px;
}

.form-label2 {
  font-size: 16px;
}

.otp {
  position: relative;
  display: block;
}

.otp a {
  position: absolute;
  right: 10px;
  top: 8px;
  z-index: 1;
  display: inline-block;
  font-size: 12px;
  color: #ed3237;
}

.modal-head2 {
  display: flex;
  text-align: left;
  font-size: 18px;
  align-items: center;
  margin-bottom: 30px;
}

.modal-head2 img {
  margin-right: 10px;
}

.form-select {
  height: 35px;
  font-size: 14px;
  border-radius: 8px;
}

.date {
  position: relative;
  display: block;
}

.date img {
  position: absolute;
  right: 10px;
  top: 9px;
}

.link-block {
  color: rgba(16, 16, 16, 0.8);
  font-size: 14px;
  display: block;
}

.link-block:hover {
  color: #ed3237;
  text-decoration: none;
}

.dropdown-menu2 {
  font-size: 14px;
}

.dropdown-menu2 .dropdown-item {
  vertical-align: middle;
  padding: 0.5rem 10px;
}

.dropdown-menu2 .dropdown-item:hover,
.dropdown-menu2 .dropdown-item:active,
.dropdown-menu2 .dropdown-item:focus {
  text-decoration: none;
  color: rgba(16, 16, 16, 1);
  background: #e4f0ff;
}

.dropdown-menu2 .dropdown-item img {
  vertical-align: bottom;
}

.dropdown-menu2 .dropdown-divider {
  margin: 0px 10px;
}

.social-media {
  display: flex;
}

.social-media a img {
  display: inline-block;
  transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
}

.social-media a {
  display: inline-block;
  margin-right: 20px;
}

.social-media a:hover img {
  transform: scale(1.2);
}

.social-media li:last-child a {
  margin-right: 0px;
}

.footer-row {
  justify-content: space-between;
}

.footer-row .col-sm-4 {
  width: auto;
}

.outline-link {
  margin-right: 30px;
  background: #fff;
  color: #2f4858;
  border: 1px solid #2f4858;
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 120px;
}

.outline-link:hover,
.outline-link:focus,
.outline-link:active,
.outline-link:focus-visible,
.outline-link:focus-within {
  text-decoration: none;
  background: #2f4858;
  color: #fff;
  border: 1px solid #2f4858;
  box-shadow: 0 0 0 0.25rem rgba(47, 72, 48, 0.25);
}

.hide-div {
  display: none;
}

.outline-link-lg {
  width: auto;
  margin-right: 0px;
}

.add-city .form-control {
  padding-left: 35px;
  width: 295px;
  max-width: 100%;
}

.add-city {
  display: inline-block;
}

.add-city .inline-text img {
  position: absolute;
  left: 10px;
  top: 10px;
}

.add-city-div {
  margin-top: 20px;
}

.add-city .inline-text {
  display: inline-block;
  position: relative;
  margin-right: 18px;
}

.hide-div.inline-block {
  display: inline-block !important;
}

.city-btn {
  width: 180px;
}

.tnb-fixed {
  position: absolute;
  z-index: 10;
  width: 100%;
  top: 0;
}

.tnb-fixed .outline-link {
  color: #ed3237;
  border: 1px solid #ed3237;
}

.tnb-fixed .outline-link:hover {
  color: #fff;
  border: 1px solid #ed3237;
  background: #ed3237;
}

.tnb-fixed .outline-link:hover,
.tnb-fixed .outline-link:focus,
.tnb-fixed .outline-link:active,
.tnb-fixed .outline-link:focus-visible,
.tnb-fixed .outline-link:focus-within {
  text-decoration: none;
  background: #ed3237;
  color: #fff;
  border: 1px solid #ed3237;
  box-shadow: 0 0 0 0.25rem rgba(237, 50, 55, 0.25);
}

.offer-card-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.offer-card-footer a.link-gray {
  color: #ed3237;
}

.owl-carousel-mobile {
  display: block;
}

.payment-method li {
  display: inline-block;
  margin-right: 5px;
  border: 1px solid rgba(16, 16, 16, 0.1);
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
}

.payment-method {
  flex-wrap: wrap;
}

.payment-method li img.visa {
  max-height: 12px;
}

.payment-method li.no-border {
  border: none;
  padding-left: 0px;
  padding-right: 0px;
}

main {
  padding-top: 80px;
}

.profile-card {
  box-shadow: 0px 0px 10px rgba(100, 100, 111, 0.2);
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 15px;
  margin-top: 10px;
  margin-right: 10px;
  height: 100%;
  padding-bottom: 0px;
}

.profile-card p {
  font-size: 14px;
}

.profile-head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.box-img {
  display: inline-flex;
  width: 100px;
  height: 100px;
  background: #2f4858;
  border-radius: 6px;
  font-size: 48px;
  font-weight: 700;
  color: #fff;
  justify-content: center;
  align-items: center;
  line-height: normal;
  max-width: 100%;
}

.label1 {
  font-size: 14px;
  color: #101010;
  margin-bottom: 10px;
}

.label2 {
  font-size: 14px;
  margin-bottom: 10px;
}

.pl15 {
  padding-left: 8px;
}

.personal-modal .modal-left {
  max-width: 100%;
  text-align: left;
  padding: 40px;
}

.personal-modal {
  max-width: 700px;
}

.link-red {
  display: block;
  font-size: 14px;
  color: #ed3237;
}

b {
  font-weight: 700;
}

.file-box {
  position: relative;
  max-width: 100px;
}

.file-box input {
  position: absolute;
  z-index: 1;
  opacity: 0;
}

.file-box .link-red {
  font-size: 12px;
  text-align: center;
}

.mobile-select {
  display: flex;
}

.mobile-select select {
  max-width: 80px;
  border-radius: 8px 0px 0px 8px;
  border-right: none;
}

.mobile-select .form-control {
  border-radius: 0px 8px 8px 0px;
}

.country-select select {
  max-width: 100%;
  display: flex;
}

.profile-card label {
  font-weight: 600;
  display: block;
}

.file-box {
  max-width: 20px;
  text-align: center;
}

.link-box {
  display: flex;
  max-width: 100px;
  justify-content: space-around;
  margin-top: 5px;
}

.mobile-select {
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  background: #fff;
}

.mobile-select .form-control {
  border: none;
}

.country-code {
  display: flex;
  align-items: center;
  width: 55px;
  height: 100%;
  padding-left: 10px;
  font-size: 14px;
  color: #212529;
  padding-left: 10px;
  margin-top: 6px;
  position: relative;
}

.country-code img.caret {
  position: absolute;
  right: 0px;
}

.flag {
  margin-right: 10px;
}

.caret {
  margin-left: 5px;
}

.mobile-data-list .dropdown-toggle::after {
  display: none;
}

.country-code-box .form-control {
  border-radius: 8px;
  border: 1px solid #d9d9d9;
}

.mobile-data-list .dropdown-menu {
  width: 280px;
  overflow-y: auto;
  font-size: 12px;
  max-height: 300px;
}

.country-code-box {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 10px;
  margin-top: 10px;
  position: relative;
}

.country-code-box .search-icon {
  position: absolute;
  z-index: 1;
  left: 10px;
  top: 10px;
}

.country-code-box .form-control {
  padding-left: 35px;
}

.mobile-data-list .dropdown .dropdown-item {
  display: flex;
  justify-content: space-between;
}

.mobile-select a:hover {
  text-decoration: none;
}

.flag {
  width: 20px;
}

.verified {
  color: #4a8e00;
  font-weight: 400;
  font-size: 12px;
  display: inline-block;
  margin-left: 10px;
}

.verified img {
  position: relative;
  top: -1.5px;
}

.pending {
  font-weight: 400;
  font-size: 12px;
  display: inline-block;
  margin-left: 10px;
  filter: grayscale(10);
}

.h1-inner {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
}

.tabbing-bar {
  margin-bottom: 60px;
}

.left-side {
  background: #f5faff;
  border: 1px solid #cfe4f9;
  padding: 20px;
  border-radius: 6px;
  font-size: 14px;
}

.block {
  margin-bottom: 30px;
}

.h3-bold {
  font-weight: 600;
}

.slidecontainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.slidecontainer input {
  width: 100%;
  margin-bottom: 10px;
}

.slider {
  /* -webkit-appearance: none; */
  width: 100%;
  height: 25px;
  background: red;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: red;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: red;
  cursor: pointer;
}

/*----Responsive starts here -----*/

@media only screen and (max-width: 1280px) {
  .add-city .form-control {
    width: 250px;
  }
}

@media only screen and (max-width: 1199px) {
  .offer-card {
    margin-left: 5px;
  }
  .offer-col .col-right p {
    padding-right: 0px;
  }

  .add-city .form-control {
    width: 205px;
  }
}

@media only screen and (max-width: 991px) {
  .modal-row1 {
    flex-direction: column;
  }
  .modal-row1 .col-sm-6 {
    width: 100%;
  }
  .site-modal .modal-left {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .city-btn {
    margin-top: 0px;
  }
  .multi-city-form {
    flex-wrap: wrap;
  }

  .add-city .form-control {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .tabbing-bar {
    margin-top: 30px;
    padding-right: 0px;
  }

  .p1 {
    margin-bottom: 30px;
  }
  .card3 {
    margin-bottom: 30px;
  }

  footer .col-sm-3 {
    margin-bottom: 20px;
  }
  .copyright {
    flex-direction: column;
    margin-top: 0px;
  }
  .copyright img {
    object-fit: contain;
    margin-bottom: 10px;
  }
}

/* new css */
.block > ul > li {
  list-style: none;
  display: flex;
  justify-content: space-between;
}

.block > ul > li > a {
  justify-content: right;
  display: flex;
  width: 100%;
  color: #ed3237;
}

.image-body {
  gap: 10px;
  padding-bottom: 10px;
}
.image_body {
  gap: 10px;
  padding-bottom: 10px;
  align-items: center;
}
.image_body .text_body {
  color: #ed3237;
}
button.room-btn {
  border: 1px solid #008000;
  color: #008000;
  background-color: #fff;
  font-size: 14px;
  font-weight: normal;
}
.price-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  height: 200px;
}
.panel-container {
  height: 260px;
}
section#amenities_section,
section#hotel_policies_section,
section#photos_section,
section#map_section {
  display: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-top: 2rem;
}
.main_content.icon-content {
  justify-content: left;
  gap: 50px;
}
.image-section img {
  width: 100%;
}
.room-heading-tab {
  border-bottom: 1px solid #878787;
  padding: 0.7rem 1rem;
}
select.select-room {
  border: 1px solid #fff;
}
.panel {
  display: none;
}
.accordion .text_body {
  color: #ed3237;
  font-size: 14px;
}
img.active-img {
  transform: rotate(180deg);
}
img.active-img.active_image {
  transform: rotate(0deg);
}

.body-container {
  padding: 1rem;
}
.cols_border {
  border-right: 1px solid #878787;
}
.row.row_grid {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding-bottom: 20px;
}
.panel_container {
  border-right: 1px solid #878787;
}
hr.mb-0 {
  width: 97%;
  margin: auto;
  text-align: center;
}
.panel_section {
  display: flex;
  text-align: justify;
  justify-content: center;
  margin: auto;
  align-items: center;
}

.main_container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.card.card_active {
  background: #ed3237;
  color: #fff;
  border: 1px solid #ed3237;
}

.main_content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: auto;
  align-items: center;
  row-gap: 30px;
}

.main_content .block {
  display: flex;
  justify-content: center;
  margin: auto 0px;
  align-items: baseline;
}

.card-content p {
  font-size: 13px;
}

.card-content.content_body {
  text-align: center;
}

button.btn.btn-select {
  border: 1px solid #ed3237;
  font-size: 16px;
  padding: 5px 30px;
}
button.btn.btn-cancel_policy {
  border: 1px solid #ed3237;
  font-size: 13px;
  padding: 5px 10px;
}
button.btn.btn-cancel {
  border: 1px solid #ed3237;
  color: #ed3237;
  font-size: 13px;
  padding: 5px 10px;
}
button.btn.btn-completed {
  border: 1px solid #008000;
  color: #008000;
  font-size: 13px;
  padding: 5px 10px;
}
span.action-text {
  color: #ed3237;
}
span.date_text {
  font-size: 13px;
}
.admin-images {
  width: 100%;
}
.pagination_data {
  flex-wrap: wrap;
  row-gap: 20px;
}
button.btn.btn-refund-status {
  border: 1px solid #2f4858;
  color: #2f4858;
  font-size: 13px;
  padding: 5px 10px;
}

.card.card_section {
  box-shadow: 0px 0px 10px rgba(100, 100, 111, 0.2);
  margin: 25px 0px;
}

.card-footer-section {
  display: flex;
  justify-content: space-between;
  padding: 20px 5px;
}

.card-left {
  color: #008000;
  font-size: 12px;
}

.card-right {
  color: #ed3237;
  font-size: 12px;
}

.card-left:hover {
  color: #008000;
  text-decoration: none;
}

.card-right:hover {
  color: #ed3237;
  text-decoration: none;
}

.change-plan {
  text-align: center;
  padding: 35px 0px;
}

h3 > span {
  color: #878787;
  font-size: 13px;
  font-weight: normal;
}

.card.view_details {
  background: #f5faff;
  border: 1px solid #cfe4f9;
}

.block-view-details {
  display: flex;
  gap: 15px;
  align-items: center;
  padding-bottom: 1.5rem;
  padding-top: 0.5rem;
}

.book-now-btn {
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
}

a.card-right {
  cursor: pointer;
}

.card_cheapest_section,
.card_fastest_section {
  cursor: pointer;
}

.main_content .block .card-img {
  margin-right: 20px;
}
.row_content {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: auto;
  align-items: baseline;
  row-gap: 30px;
}

.header_section {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: auto;
  align-items: center;
  column-gap: 20px;
  padding-bottom: 15px;
}

.header_section .h3-bold {
  text-align: center;
  align-items: center;
  display: flex;
}

span.hading_check {
  color: #101010;
  font-weight: 600;
}

.block.block_right {
  margin-bottom: 0px;
  position: relative;
}

hr {
  margin: 0rem 0rem 1.2rem 0rem;
}

ul.dropdown-menu.select_day {
  width: 5rem;
  min-width: auto;
}

.select_day li {
  display: flex;
  justify-content: center;
}

.left-side.left-side_1 {
  background: #fff;
  border: 1px solid #cfe4f9;
  margin-bottom: 20px;
}

.input_group {
  background-color: #ed3237;
}

input.form-control {
  font-size: 12px;
}

span.res_text {
  color: #008000;
  font-size: 12px;
  line-height: normal;
}

li.right_btn.py-1 {
  display: block;
}

span.text_btn {
  font-size: 12px;
  color: #101010;
}

label.email_text {
  font-size: 12px;
}

.card.card_checkout {
  box-shadow: 0px 0px 10px rgba(100, 100, 111, 0.2);
  margin-bottom: 20px;
  background-color: #f5faff;
}

.row_container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;
  margin-bottom: 20px;
}

.card_main_content {
  display: flex;
  justify-content: center;
  margin: 0px;
  align-items: baseline;
}

.main_content .card_block {
  display: flex;
  justify-content: center;
  margin: 0px;
  align-items: baseline;
}

.block .content_body {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  column-gap: 20px;
}

span a.second_card {
  float: right;
  color: #ed3237 !important;
  font-size: 12px !important;
}

div#child_row,
div#infant_row {
  display: none;
}

.inline-text .form-control.form_check {
  border-radius: 4px;
  line-height: 3;
}

h3#child_1,
h3#infant_1,
h3#adult_1 {
  cursor: pointer;
}

p.text-important {
  color: #e57d02 !important;
}

.payment_btn {
  text-align: center;
}

.col-md-6.col-sm-6 .payment-method {
  justify-content: flex-end;
}

.heading_check {
  color: #101010;
  font-weight: 600;
  font-size: 12px;
}

.text-table {
  color: #2f4858;
  font-family: Open Sans;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.status-co {
  color: #008000;
}

.line-ind {
  text-indent: 85px;
}

.box-ind {
  width: 100%;
  display: grid;
  justify-content: center;
  margin: auto;
  text-align: end;
}
.confirm-box {
  width: 100%;
}
.box-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
}

ul.box-dropdown {
  display: none;
}
li.box-content {
  list-style: none;
}
.box-subcontent {
  display: flex;
  padding: 0px 0px;
  justify-content: space-between;
}
.box-ind-main {
  text-align: center;
  border: 1px solid rgb(254 245 240);
  background-color: #fafafa;
  padding: 30px 0px;
  margin: auto;
  display: flex;
  justify-content: center;
  margin-top: 0;
}

.main_content.main_card_content {
  justify-content: center;
  column-gap: 10%;
  padding: 10px 0px;
}

ul.confirmation-content li {
  line-height: 2;
  color: #212529;
}
.block_heading {
  font-size: 1rem;
  color: #212529;
  font-weight: 500;
}
.passenger_text,
.label_text,
.check_label {
  color: #212529;
}
.block_main_container {
  display: flex;
  justify-content: start;
  gap: 20px;
}

.image-arrow {
  position: absolute;
  display: flex;
  margin-top: 11px;
  margin-left: -10px;
  transform: rotate(90deg);
}
img.image-arrow.active {
  transform: rotate(180deg);
}
.sub-heading-confirmation {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: auto;
  align-items: center;
  margin-right: 30px;
  padding-bottom: 15px;
  row-gap: 20px;
}

.card_confirmation {
  background: #f5faff;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: white;
}
.confirmation-box {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 1rem;
}
.bottom_text {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  bottom: 0px;
  margin-bottom: 25px;
}
.backg-code {
  background: #f5faff;
}
.header_passenger {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: auto;
  align-items: center;
  column-gap: 20px;
  padding-bottom: 15px;
  row-gap: 20px;
}
button.passenger-btn {
  background-color: #f5fafe;
  border-radius: 10px;
  border: 1px solid #2f4858;
}
.passenger-text {
  display: flex;
  justify-content: center;
  margin: auto;
  align-items: baseline;
  gap: 5px;
}
p.card-passenger {
  font-size: 16px;
}
.card-content.content_body {
  display: block;
}

.row_container::after {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: gray;
  position: absolute;
  left: 79px;
  margin-top: 8px;
}

.main_div::before {
  content: "";
  border-right: 1px solid;
  position: absolute;
  left: 80px;
  width: 5px;
  height: 55%;
  z-index: 5;
  margin-top: 11px;
}

.payment_container::after {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: gray;
  position: absolute;
  left: 65px;
  margin-top: 8px;
}

.payment_div::before {
  content: "";
  border-right: 1px solid;
  position: absolute;
  left: 65px;
  width: 5px;
  height: 55%;
  z-index: 5;
  margin-top: 11px;
}

.payment_container {
  display: flex;
  flex-wrap: nowrap;
  column-gap: 40px;
  margin-bottom: 20px;
}

.payment_col {
  display: flex;
  align-items: center;
}

li.payment-list {
  margin-bottom: 0px;
}

.form-contact {
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.select-container {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

/* hotel */

.select-tab-container {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  gap: 10%;
  border-bottom: 1px solid #cfe4f9;
}

.tab-bold {
  font-size: 16px;
  cursor: pointer;
}

.image-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
}

img.img-fluid.show-img {
  width: 100%;
}

.right-section {
  display: flex;
  justify-content: center;
  margin: auto;
  align-items: center;
  background: #f5faff;
  height: 100%;
}

a.remove-btn:hover,
a.select-btn:hover {
  text-decoration: none;
}

.main_card {
  padding-right: 0px;
}

.image-content {
  text-align: center;
}

.heart-section {
  position: absolute;
  background: #f5faff;
  padding: 10px 0px;
  right: 0px;
  justify-content: right;
}

.select-tab-container {
  gap: 7%;
  margin-top: 20px;
}

span.content-text {
  font-size: 12px;
  font-weight: normal;
}

span.content-text-light {
  font-size: 12px;
  font-weight: normal;
  color: #ed3237;
}

.simple-btn {
  border: 1px solid #101010;
  border-radius: 8px;
  font-size: 12px;
  background-color: #fff;
  padding: 2px 10px;
}

.content-section {
  display: flex;
  justify-content: center;
  margin: auto;
  align-items: center;
}

.block-content {
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
  align-items: baseline;
}

.col-left img {
  background-blend-mode: darken;
  background-size: cover;
  overflow: hidden;
  position: relative;
}

.tab-bold.tab-active {
  color: #fff;
  font-size: 16px;
}

.form-control {
  line-height: 2;
}

select.select-age {
  width: 50px;
  color: #010101;
}
a.select-btn {
  color: #707070;
  font-weight: 600;
}
.row_content .block {
  width: 25%;
}
.block.block-section {
  align-items: center;
  gap: 10px;
}
.check-in.check-out {
  display: flex;
  gap: 20px;
  padding: 1rem;
  margin: 0px;
  align-items: center;
}
.heading_text {
  padding-top: 1rem;
  padding-left: 1rem;
}
.circle-image {
  width: 90px;
  height: 70px;
  border-radius: 50%;
  background-color: #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.d-flex.card_body {
  gap: 15px;
}
img.gallery-icon {
  border-radius: 10px;
  width: 100%;
}
img.img-fluid.img-gallery {
  width: 100%;
}
section#amenities_section .main_content {
  padding: 1rem;
  border-bottom: 1px solid #878787;
}

.heading_section {
  padding: 2rem 1rem 1rem 1rem;
}
span.content-text.content-rating {
  background-color: #2f4858;
  color: #fff;
  padding: 1px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.inline-text1 .select-dropdown {
  margin-right: 0px;
}
.inline-text1 {
  color: #878787;
  font-size: 12px;
  border: 1px solid #d9d9d9;
  line-height: 3;
  border-radius: 4px;
  padding: 0.375rem 0.75rem;
  margin-right: 20px;
  width: 100%;
}
.box-heading {
  font-size: 1rem;
  color: #212529;
  font-weight: 100;
}

.w3-animate-left {
  position: relative;
  animation: animateleft 0.4s;
}
@keyframes animateleft {
  from {
    left: -10px;
    opacity: 0;
  }
  to {
    left: 0;
    opacity: 1;
  }
}
.w3-animate-top {
  animation: animatetop 0.4s;
}
@keyframes animatetop {
  from {
    top: -200px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
.w3-animate-zoom {
  animation: animatezoom 0.6s;
}
@keyframes animatezoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.w3-animate-fading {
  animation: fading 2s;
}
@keyframes fading {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

section.upcoming_section {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: #fff;
}
.card.card_upcmoing {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
}
.travel_section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 15px;
}
.block_btn {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  row-gap: 10px;
}
.main_content.col_section {
  justify-content: flex-start;
  column-gap: 60px;
  row-gap: 25px;
}
.left-profile-section {
  width: 25%;
}
.right-profile-section {
  width: 75%;
}
.admin-dashboard {
  max-width: 980px;
  margin: auto;
}
.right-profile-section {
  flex-wrap: wrap;
  /* gap: 8px!important; */
}
.profile-section {
  padding-bottom: 20px;
}
.action-icon {
  max-width: 135px;
}
section#admin-profile {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: #fff;
}
.action-text,
.d-flex.gap-2.align-items-center img {
  cursor: pointer;
}
.left-div h1 {
  margin-bottom: 0px;
}
.add_icon {
  position: absolute;
  right: 0;
}
.item-list {
  display: flex;
  justify-content: space-between;
}
.gallery-container {
  padding: 0.5rem 0rem;
}
a.remove-btn {
  color: #ed3237;
}
.book-row .price_text {
  font-size: 12px;
}
img.left_image {
  height: 445px;
}
.form_select {
  width: 75px;
  border: none;
  padding: 0px;
}
.form_select:focus {
  border-color: unset !important;
  box-shadow: unset;
}
.gallery-container .item {
  width: 24%;
}
.res_add {
  display: none;
}
.filter-add .add_icon {
  position: relative;
}
@media screen and (max-width: 992px) {
  .bottom_text {
    position: relative;
  }
  .admin-dashboard {
    padding: 5px 20px;
  }
  .row_content {
    flex-wrap: wrap;
  }
  .line_container {
    display: none;
  }
  table.table th {
    display: none;
  }
  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  th,
  td {
    width: 100%;
    display: block;
    text-align: end;
  }
  table.table tbody tr {
    margin-bottom: 30px;
    display: block;
  }
  .h1-inner {
    font-size: 20px;
    line-height: 45px;
  }
  .box-ind {
    width: 100%;
  }
  .payment_btn {
    padding-bottom: 20px;
  }
  .row_container {
    flex-wrap: inherit;
  }
  .payment_div::before {
    height: 50%;
  }

  li.li-list {
    text-align: center;
    padding-bottom: 10px;
  }
  a.btn.outline-link {
    margin: auto;
  }
  .image-container {
    gap: 10px;
  }
  .image-container img {
    width: 100%;
  }
  .main_card {
    padding-right: 1rem;
  }
  .heart-section {
    right: 15px;
  }
  .select-tab-container {
    flex-wrap: wrap;
    row-gap: 15px;
  }
  .circle-image {
    margin: auto 0px;
  }
  .row.femail_row {
    width: 100%;
  }
  .photo-gallery {
    padding-bottom: 20px;
  }
  section#room_section .inline-form {
    margin-bottom: 10px;
  }

  .inline-text1 {
    margin-right: 0px;
  }
  .inline-text.select-night {
    margin-top: 10px;
    padding: 0px;
  }
  .col-sm-3 .right-section {
    padding: 2rem 0px;
  }
}

@media screen and (max-width: 400px) and (min-width: 300px) {
  .footer-row .col-sm-3 {
    width: 100%;
  }
  .col-md-6.col-sm-6 .payment-method {
    padding-top: 20px;
  }
}
@media screen and (max-width: 600px) and (min-width: 300px) {
  img.travel_images {
    width: 100%;
  }
  .add_icon {
    position: relative;
    margin-bottom: 15px;
    text-align: left;
    padding: 0px !important;
  }
}
@media screen and (max-width: 668px) and (min-width: 600px) {
  .res_add {
    padding-bottom: 24px;
  }
}
@media screen and (max-width: 575px) and (min-width: 300px) {
  .photo-gallery {
    padding: 10px;
  }
}
@media screen and (max-width: 530px) and (min-width: 300px) {
  .left-profile-section {
    width: 100%;
  }
  .right-profile-section {
    width: 100%;
  }
  .profile-section {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 900px) and (min-width: 300px) {
  .col-sm-6.px-0.panel-container {
    width: 50% !important;
  }
  .panel-container {
    height: auto;
  }
  .travel_section h3.fs-5.mb-0 {
    font-size: 1rem !important;
    margin: auto 0px !important;
  }
  .row.row_panel {
    margin: auto;
  }
  .row.row_panel .body-container {
    padding: 0px 8px;
  }
}

@media screen and (max-width: 600px) and (min-width: 400px) {
  .footer-row .col-sm-3 {
    width: 50%;
  }
  .col-md-6.col-sm-6 .payment-method {
    padding-top: 20px;
  }
}

@media screen and (max-width: 768px) and (min-width: 300px) {
  .main_div::before {
    height: 39%;
  }

  .row_content .block {
    width: 50%;
  }
}

@media screen and (max-width: 900px) and (min-width: 400px) {
  .col-sm-3 {
    flex: 0 0 auto;
    width: 100%;
  }
  .col-sm-4 {
    width: 100%;
  }
  .col-sm-5,
  .col-sm-7 {
    width: 100%;
  }
  .col-sm-8 {
    width: 100%;
  }
  .col-sm-6.col-md-6 {
    width: 100%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 100%;
  }
  .col-sm-3.col-md-3.pb-4.checkout-col {
    width: 50%;
  }
  .left-side {
    font-size: 10px;
  }
  .card_cheapest_section,
  .card_fastest_section {
    font-size: 14px;
  }
  h3,
  h5 {
    font-size: 15px;
  }
  button.btn.btn-select,
  button.btn.btn-cancel,
  button.btn.btn-completed,
  button.btn.btn-refund-status {
    font-size: 13px;
  }
  .lg-btn {
    font-size: 14px;
  }
  .select-dropdown .dropdown-toggle {
    font-size: 14px;
  }
  label.form-check-label {
    font-size: 14px;
  }
  .header_section .h3-bold {
    font-size: 15px;
  }
  .h3-bold {
    font-size: 15px;
  }
  span.hading_check {
    font-size: 12px;
  }
  .form-check span {
    font-size: 12px;
  }
  .block > ul > li span {
    font-size: 12px;
  }
  .header_section span {
    font-size: 13px;
  }
  .content_body p {
    font-size: 14px;
  }
  .row_container::after {
    left: 70px;
  }
  .main_div::before {
    left: 70px;
  }
  .payment_container::after {
    left: 50px;
  }
  .payment_div::before {
    left: 50px;
  }

  .block > ul > li .form-check {
    display: flex;
    gap: 5px;
    align-items: center;
  }
}

@media screen and (max-width: 933px) and (min-width: 900px) {
  .main_div::before {
    height: 39%;
  }
}
@media screen and (max-width: 992px) and (min-width: 580px) {
  .col-sm-5,
  .col-sm-3,
  .col-sm-9,
  .col-sm-7 {
    width: 100%;
  }
}

@media screen and (max-width: 900px) and (min-width: 600px) {
  .footer-row .col-sm-3 {
    width: 25%;
  }
  .col-sm-5,
  .col-sm-7 {
    width: 100%;
  }
  .col-md-6.col-sm-6 .payment-method {
    padding-top: 20px;
  }
  .row_content .block {
    width: 50%;
  }
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  main.container {
    max-width: 900px;
  }
  .teaxes_col .right-section {
    padding: 1rem 0rem;
  }
}

@media screen and (max-width: 1024px) and (min-width: 900px) {
  .col-md-4,
  .col-md-4 {
    width: 100%;
  }
  .circle-image {
    margin: auto 0px;
  }
  .row.femail_row {
    width: 100%;
  }
}
@media screen and (max-width: 1120px) and (min-width: 900px) {
  .payment_select_col {
    width: 100%;
  }
  .row.row_grid {
    padding-bottom: 40px;
  }
}
@media screen and (max-width: 1240px) and (min-width: 900px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
  .form-contact {
    display: block;
  }

  .image-container img {
    width: 100%;
    gap: 10px;
  }
}
